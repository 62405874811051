import { GetterTree } from 'vuex';
import { IRootState } from '@store/store';
import { IUserProjectsState } from './Interfaces';

export const getters: GetterTree<IUserProjectsState, IRootState> = {
    activeProjects(state: IUserProjectsState) {
        return state.projects.filter((project) => {
            return project.status === 'Новый' || project.status === 'Выполнение работ';
        });
    },
    pausedProjects(state: IUserProjectsState) {
        return state.projects.filter((project) => project.status === 'Приостановлен');
    },
    completedProjects(state: IUserProjectsState) {
        return state.projects.filter((project) => project.status === 'Завершен');
    },
    filters: (state) => {
        return state.filters;
    },
    filtersApply: (state) => {
        return state.filtersApply;
    },
    projectStatuses: (state) => {
        return state.projectStatuses;
    },
    userProjectsGetting: (state) => {
        return state.userProjectsGetting;
    },
}
