import { GetterTree } from 'vuex';
import { IRootState } from '@store/store';
import { IProjectRespondsState } from './interfaces/Interfaces';

export const getters: GetterTree<IProjectRespondsState, IRootState> = {
    respondsLoaded: (state) => state.respondsLoaded,
    projectResponds: (state) => state.projectResponds,

    respondsCount(state) {
        return state.projectResponds.reduce((count, respond) => count += respond.count, 0);
    },
}
