import { IProjectTeamMember, IMemberRole } from '@store/modules/project-team/interfaces/Interfaces';
import { IProjectUserResponse } from '@store/modules/project-team/interfaces/ApiResponses';
import { UserRightsTransformer } from './UserRightsTransformer';

export class UsersTransformer {
    public toDomain(usersResponse: IProjectUserResponse[]): IProjectTeamMember[] {
        try {
            const userRightsTransformer = new UserRightsTransformer();
            const teamMembers = [] as IProjectTeamMember[];
            for (const user of usersResponse) {
                const role: IMemberRole = {
                    uuid: user.role?.id ?? '',
                    label: user.role?.name ?? 'Не назначена',
                    value: user.role?.name ?? 'Не назначена',
                };

                const rights = userRightsTransformer.toDomain(user.rights);

                const projectTeamMember = {
                    uuid: user.userId,
                    role,
                    rights,
                    active: user.isActive,
                    portalPart: user.portalPart,
                    engineerPart: user.engineerPart,
                } as IProjectTeamMember;

                teamMembers.push(projectTeamMember);
            }
            return teamMembers;
        }
        catch (error: any) {
            throw new Error(`Users Transformer Error: ${error.message ? error.message : ''}`);
        }
    }
    public toRequest() {}
}
