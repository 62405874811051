import { IWorkResponse } from '../interfaces/ApiResponses';
import { IWork } from '../interfaces/Interfaces';
import { ConstructsTransformer } from './ConstructsTransformer';

export class WorkListTransformer {
    public toDomain(workListResponse: IWorkResponse[]) {
        try {
            const constructsTransformer = new ConstructsTransformer();
            const workList = [] as IWork[];
            for (const workResponse of workListResponse) {
                workList.push({
                    uuid: workResponse.id,
                    title: workResponse.name,
                    constructs: constructsTransformer.toDomain(workResponse.constructs),
                    children: this.toDomain(workResponse.children),
                });
            }
            return workList;
        }
        catch (error: any) {
            throw new Error(`Work List Transformer Error: ${error.message ? error.message : ''}`);
        }
    }
    public toRequest() {}
}
