import { IUserState, IErrorsPopup } from './Interfaces';
import { MutationTree } from 'vuex';
import { UserMutationTypes } from './Types';
import { UserTransformer } from './transformers/UserTransformer';

const userTransformer = new UserTransformer();

export const mutations: MutationTree<IUserState> = {
    [UserMutationTypes.M_LOGIN](state, userResponse: any) {
        state.userInfo = userTransformer.toDomain(userResponse);
    },
    [UserMutationTypes.M_AUTH_USER](state, payload: boolean) {
        state.isAuthUser = payload;
    },
    [UserMutationTypes.M_USER_TOKEN](state, payload: string | null) {
        state.userToken = payload;
    },
    [UserMutationTypes.M_LOGOUT](state) {
        state.isAuthUser = false;
        state.userToken = '';
        state.userInfo = {
            id: '',
            fullName: '',
            login: '',
            fromSmartcom: null,
            email: '',
            phone: '',
            favorites: [],
            engineerPart: true,
            portalPart: true,
        };
    },
    [UserMutationTypes.M_SET_TOKEN_REFRESHING](state, payload: boolean) {
        state.tokenRefreshing = payload
    },
    [UserMutationTypes.M_SET_BACK_ERROR](state, errorData: IErrorsPopup): void {
        state.backError = errorData;
    },
    [UserMutationTypes.M_SET_USER_RIGHT_ONLY_GSN](state, val: boolean) {
        state.userRightOnlyGSN = val;
    },
}
