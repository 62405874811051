import { IProjectTeamMember, IProjectTeam } from '@store/modules/project-team/interfaces/Interfaces';
import { IUserInfoResponse } from '@store/modules/project-team/interfaces/ApiResponses';

export class ProjectTeamTransformer {
    public toDomain(separatedProjectTeam: Map<IProjectTeamMember, IUserInfoResponse>): IProjectTeam {
        try {
            const projectTeam = {
                daconMembers: [],
                smartcomMembers: [],
                allMembers: [],
            } as IProjectTeam;

            for (const [member, userInfo] of separatedProjectTeam.entries()) {
                const projectMember = {
                    ...member,
                    fullName: userInfo.name ? userInfo.name : 'unknown',
                    email: userInfo.email ? userInfo.email : 'unknown',
                    phone: userInfo.phone ?? 'unknown',
                    fromSmartcom: userInfo.fromSmartcom,
                    daconMember: userInfo.portalPart,
                    smartcomMember: userInfo.engineerPart,
                    position: userInfo?.position ?? 'Не указана',
                    legalEntity: userInfo?.entity?.name ?? 'Не указано',
                    entityId: userInfo?.entity?.id ?? undefined,
                    individualId:  userInfo?.individualPerson?.id ?? undefined,
                    selected: false,
                    docStatuses: userInfo?.docStatuses,
                    isAdmin: userInfo?.isAdmin ?? null,
                }
                if (userInfo.portalPart) {
                    projectTeam.daconMembers.push(projectMember);
                }
                if (userInfo.engineerPart) {
                    projectTeam.smartcomMembers.push(projectMember);
                }
                projectTeam.allMembers.push(projectMember);
            }

            return projectTeam;
        }
        catch (error: any) {
            throw new Error(`Project Team Transformer Error: ${error.message ? error.message : ''}`);
        }
    }
    public toRequest() {}
}
