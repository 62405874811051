import { GetterTree, Module } from "vuex";
import { getters } from "./Getters";
import { IWorkListPopupsState } from "./Interfaces";
import { IRootState } from "@store/store";
import { PopupImpl } from '@store/Popup';

const state = {
    workAddingPopupToggler: false,
    constructMovingPopupToggler: false,
    constructDocumentationPopupToggler: false,
    executiveDocumentAddingPopupToggler: false,
    documentFilesPopupToggler: false,
    deleteWorkPopupToggler: false,
} as IWorkListPopupsState;

class WorkListPopupsStore extends PopupImpl<IWorkListPopupsState> {
    namespaced: boolean;
    state: IWorkListPopupsState;
    getters: GetterTree<IWorkListPopupsState, IRootState>;

    constructor() {
        super();
        this.namespaced = true;
        this.state = state;
        this.getters = getters;
    }
}

export const storeWorkListPopups = new WorkListPopupsStore() as Module<IWorkListPopupsState, IRootState>;
