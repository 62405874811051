import { GetterTree } from "vuex";
import { IWorkListPopupsState } from "./Interfaces";
import { IRootState } from "@store/store";

export const getters: GetterTree<IWorkListPopupsState, IRootState> = {
    workAddingPopupToggler: (state) => state.workAddingPopupToggler,
    constructMovingPopupToggler: (state) => state.constructMovingPopupToggler,
    constructDocumentationPopupToggler: (state) => state.constructDocumentationPopupToggler,
    executiveDocumentAddingPopupToggler: (state) => state.executiveDocumentAddingPopupToggler,
    documentFilesPopupToggler: (state) => state.documentFilesPopupToggler,
    deleteWorkPopupToggler: (state) => state.deleteWorkPopupToggler,
}
