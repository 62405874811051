export enum InputControlActionTypes  {
    A_PROJECT_STORE_LISTENER = 'A_PROJECT_STORE_LISTENER',
    A_GET_INPUT_CONTROL_DOCUMENTS = 'A_GET_INPUT_CONTROL_DOCUMENTS',
    A_GET_LOG_OF_INPUT_CONTROL = 'A_GET_LOG_OF_INPUT_CONTROL',
}

export enum InputControlMutationTypes {
    M_SET_INPUT_CONTROL_DOCUMENTS = 'M_SET_INPUT_CONTROL_DOCUMENTS',
    M_SET_INPUT_CONTROL_LOADED = 'M_SET_INPUT_CONTROL_LOADED',
}
