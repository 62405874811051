import { IAllUsers, IAllUsersResponse } from "../Interfaces";
import { UserAppsTransformer } from "./UserAppsTransformer";

export class AllUsersTransformer {
    public toDomain(allUsersResponse: IAllUsersResponse): IAllUsers {
        try {
            const userAppsTransformer = new UserAppsTransformer();
            const allUsers = {
                page: allUsersResponse.page,
                pages: allUsersResponse.pages,
                users: [],
            } as IAllUsers;

            for (const user of allUsersResponse.users) {
                allUsers.users.push({
                    uuid: user.userId,
                    fullName: user.name,
                    projects: userAppsTransformer.toDomain(user.apps),
                    selected: false,
                });
            }

            return allUsers;
        }
        catch (error: any) {
            throw new Error(`All Users Transformer Error: ${error.message ? error.message : ''}`);
        }
    }
    public toRequest() {}
}
