import { IUserRightInAppResponse, IUserRightInApp } from "../Interfaces";

export class UserRightsInAppTransformer {
    public toDomain(userRightInApp: IUserRightInAppResponse[]): IUserRightInApp[] {
        try {
            const userRights = [] as IUserRightInApp[];

            for (const right of userRightInApp) {
                userRights.push({
                    uuid: right.id,
                    title: right.name,
                });
            }

            return userRights;
        }
        catch (error: any) {
            throw new Error(`User Rights In App Transformer Error: ${error.message ? error.message : ''}`);
        }
    }
    public toRequest() {}
}
