import { MutationTree } from 'vuex';
import {
    ICategoryResponse,
    IDocument,
    IDocumentsState,
    IComment,
    IPackPDResponse,
    IDocumentResponse,
    IFile,
} from './Interfaces';
import { ProjectDocumentsMutations } from './Types';
import { IStatusResponse } from './Interfaces';
import { StatusTransformer } from './transformers/StatusTransformer';
import { CategoryListTransformer } from './transformers/CategoryListTransformer';
import { PackPDTransformer } from './transformers/PackPDTransformer';
import { DocumentsTransformer } from '../project/transformers/DocumentsTransformer';
const statusTransformer = new StatusTransformer();
const docsTransformer = new DocumentsTransformer();
const categoryListTransformer = new CategoryListTransformer();
const packPDTransformer = new PackPDTransformer();

export const mutations: MutationTree<IDocumentsState> = {
    [ProjectDocumentsMutations.M_SET_PROJECT_DOCUMENTS_LOADED](state, loadedState) {
        state.projectDocumentsLoaded = loadedState;
    },

    [ProjectDocumentsMutations.M_SET_PROJECT_DOCUMENTS](state, documents: IDocumentResponse[]) {
        state.documents = docsTransformer.toDomain(documents);
    },

    [ProjectDocumentsMutations.M_SET_SELECTED_DOCUMENTS](state, selectedDocuments: string[]) {
        state.selectedDocuments = selectedDocuments;
    },

    [ProjectDocumentsMutations.M_SET_DOCUMENT_STATUSES](state, statuses: IStatusResponse[]) {
        state.statuses = statusTransformer.toDomain(statuses);
    },

    [ProjectDocumentsMutations.M_SET_CATEGORY_LIST](state, categories: ICategoryResponse[]) {
        state.categoryList = categoryListTransformer.toDomain(categories);
    },

    [ProjectDocumentsMutations.M_SET_VIEWING_DOCUMENT](state, document: IDocument | null) {
        state.viewingDocument = document;
    },

    [ProjectDocumentsMutations.M_SET_COMPOSITE_SHOW](state, isShow: boolean) {
        state.compositeShow = isShow;
    },

    [ProjectDocumentsMutations.M_SET_COMMENTS](state, comments: IComment[]) {
        state.comments = comments;
    },

    [ProjectDocumentsMutations.M_SET_COMMENTS_LOADED](state, val: boolean) {
        state.commentsLoaded = val;
    },

    [ProjectDocumentsMutations.M_SET_PACK_PD_OPTIONS](state, val: IPackPDResponse[]) {
        state.packPDList = packPDTransformer.toDomain(val);
    },

    [ProjectDocumentsMutations.M_SET_FILTERS](state, val: any[]) {
        state.filters = val;
    },

    [ProjectDocumentsMutations.M_SET_FILTERS_APPLY](state, val: boolean) {
        state.filtersApply = val;
    },

    [ProjectDocumentsMutations.M_SET_UNREAD_DOCUMENTS](state, payload: string[]) {
        state.documentsUnread = payload;
    },

    [ProjectDocumentsMutations.M_SET_DRAGGING_STATE](state, payload: boolean) {
        state.draggingState = payload;
    },

    [ProjectDocumentsMutations.M_SET_VIEW_MODE](state, payload: string) {
        state.viewMode = payload;
    },

    [ProjectDocumentsMutations.M_SET_DOCUMENT_FILES](state, payload: { idDoc: string, files: IFile[] }): void {
        function recursion(array) {
            for (const item of array) {
                if (item.uuid === payload.idDoc) {
                    item.files = payload.files;
                    return;
                }
                if (item.children && item.children.length) {
                    recursion(item.children);
                }
            }
        }
        recursion(state.documents);
    },
    [ProjectDocumentsMutations.M_SET_DOCUMENT_NAME](state, payload: { id: string, name: string }): void {
        function recursion(array) {
            for (const item of array) {
                if (item.uuid === payload.id) {
                    item.title = payload.name;
                    return;
                }
                if (item.children && item.children.length) {
                    recursion(item.children);
                }
            }
        }
        recursion(state.documents);
    },
};
