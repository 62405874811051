import { IConstruct, IConstructStatus, IDockPack } from '../interfaces/Interfaces';
import { IConstructResponse } from '../interfaces/ApiResponses';
import { ConstructDocumentsTransformer } from './ConstructDocumentsTransformer';

export class ConstructsTransformer {
    public toDomain(constructsResponse: IConstructResponse[]) {
        try {
            const constructDocumentsTransformer = new ConstructDocumentsTransformer();
            const constructsList = [] as IConstruct[];

            for (const construct of constructsResponse) {
                const status = {
                    uuid: construct.status?.id,
                    title: construct.status?.name,
                    color: construct.status?.color,
                } as IConstructStatus;

                const dockPack = {
                    uuid: construct.docPack.id,
                    title: construct.docPack.name,
                } as IDockPack;

                constructsList.push({
                    uuid: construct.id,
                    title: construct.name,
                    plan: construct.plan,
                    fact: construct.fact,
                    startDate: construct.startDate,
                    endDate: construct.endDate,
                    status,
                    dockPack,
                    documents: constructDocumentsTransformer.toDomain(construct.documents),
                    selected: false,
                    unit: construct.unit,
                    packED: construct.packED ? {
                        id: construct.packED.id,
                        title: construct.packED.name,
                    } : null,
                });
            }

            return constructsList;
        }
        catch (error: any) {
            throw new Error(`Constructs Transformer Error: ${error.message ? error.message : ''}`);
        }
    }
    public toRequest() {}
}
