import { Module } from 'vuex'
import { actions } from './Actions'
import { mutations } from './Mutations'
import { getters } from './Getters'
import { IProjectState } from './Interfaces'
import { IRootState } from '@store/store'

const state = {
    projectLoaded: false,
    project: {},
    projectMarkKits: [],
    memberRoles: [],
    memberRolesJuristic: [],
    searchResult: {},
    searchingProcess: false,
} as IProjectState

export const storeProject = {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
} as Module<IProjectState, IRootState>
