import { IUserProject, IUserProjectResponse } from '../Interfaces';

export class UserProjectsTransformer {
    public toDomain(projectsResponse: IUserProjectResponse[]): IUserProject[] {
        try {
            const userProjects = [] as IUserProject[];

            for (const project of projectsResponse) {
                userProjects.push({
                    id: project.appId,
                    title: project.name,
                    address: project.objectAddress,
                    description: project.objectDescription,
                    status: project.status,
                    tasksCount: project.tasks,
                    documentsCount: project.documents,
                    membersCount: project.users,
                    engineerPartUsersCount: project.engineerPartUsers,
                    portalPartUsersCount: project.portalPartUsers,
                    startDate: project.startDate,
                    endDate: project.endDate,
                    url: project.link,
                });
            }

            return userProjects;
        }
        catch (error: any) {
            throw new Error(`User Projects Transformer Error: ${error.message ? error.message : ''}`);
        }
    }
    public toRequest() {}
}
