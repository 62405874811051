import { ActionTree } from 'vuex';
import { IRootState } from '@store/store';
import {
    IWorkListState,
    IWork,
    IConstruct,
} from './interfaces/Interfaces';
import { WorkListActions, WorkListMutations } from './Types';
import { AxiosResponse } from 'axios';
import http from '@/http';
import { methods } from '@/ones-api';
import { FormDataMaker } from '@/utility/FormDataMaker';
import { IWorkResponse } from './interfaces/ApiResponses';
import { IAddNewWorkPostData, ITransferPostData } from './interfaces/ApiRequests';
import { IErrorsPopup } from '@store/modules/user/Interfaces';

const openBackErrorPopup = (errorText: string = ''): IErrorsPopup => {
    return {
        isOpen: true,
        errorText,
    };
};

export const actions: ActionTree<IWorkListState, IRootState> = {
    async [WorkListActions.A_PROJECT_STORE_LISTENER]({ commit, dispatch }, projectId) {},

    async [WorkListActions.A_GET_WORK_LIST]({ state, commit, dispatch }, projectId) {
        try {
            const apiMethod = methods['works/list']({
                appId: projectId,
            });
            const { data }: AxiosResponse<IWorkResponse> = await http.get(apiMethod.url, { params: apiMethod.params });

            commit(WorkListMutations.M_SET_WORK_LIST, data);
            dispatch(WorkListActions.A_GROUP_CONSTRUCTS_BY_PACKS, state.workList);
        } catch (error) {
            return Promise.reject(error);
        }
    },

    async [WorkListActions.A_SET_WORK_LIST_LOADED] ({ commit }, val) {
        commit(WorkListMutations.M_SET_WORK_LIST_LOADED, val);
    },

    [WorkListActions.A_GROUP_CONSTRUCTS_BY_PACKS]({ commit, dispatch }, workList: IWork[]) {
        for (const work of workList) {
            if (work.children.length) {
                dispatch(WorkListActions.A_GROUP_CONSTRUCTS_BY_PACKS, work.children);
            }
            commit(WorkListMutations.M_GROUP_CONSTRUCT_BY_PACKS, work.constructs);
        }
    },

    async [WorkListActions.A_ADD_NEW_WORK](
        { commit },
        payload: {
            data: IAddNewWorkPostData,
            workToAddInto: IWork[],
            workToAdd: IWork,
            isRootWorkListSelected: boolean,
        },
    )
    {
        try {
            const apiMethod = methods['works/new-structure']();
            await http.post(apiMethod.url, payload.data)
                .then((response) => {
                    payload.workToAdd.uuid = response.data.id
                });
            commit(WorkListMutations.M_ADD_NEW_WORK, payload);
        } catch (error) {
            this.dispatch(
                'storeUser/A_SET_BACK_ERROR',
                openBackErrorPopup('Не удалось создать структуру работ')
            );
            return Promise.reject(error);
        }
    },

    [WorkListActions.A_SET_ACTION_ON_CONSTRUCT]({ commit }, construct: IConstruct) {
        commit(WorkListMutations.M_SET_ACTION_ON_CONSTRUCT, construct);
    },

    async [WorkListActions.A_ADD_FACT_TO_CONSTRUCT](
        { commit },
        factAddingPayload: {
            startDate: any;
            endDate: any;
            taskDeadline: any;
            projectId: number;
            construct: IConstruct;
            fact: number;
            file: string | ArrayBuffer | null;
        },
    ){
        try {
            const apiMethod = methods['works/set-fact']()
            const formData = new FormDataMaker({
                startDate: factAddingPayload.startDate,
                endDate: factAddingPayload.endDate,
                taskDeadline: factAddingPayload.taskDeadline,
                appId: factAddingPayload.projectId,
                constructId: factAddingPayload.construct.uuid,
                fact: factAddingPayload.fact,
                file: factAddingPayload.file,
            }).make();

            commit(WorkListMutations.M_SET_ADDING_FACT_CONSTRUCT_ID, factAddingPayload.construct.uuid);

            const response: any = await http.post(apiMethod.url, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data; boundary=-----boundary---0',
                },
            });

            if (!response.error) {
                commit(WorkListMutations.M_SET_ADDING_FACT_CONSTRUCT_ID, null);
                commit(WorkListMutations.M_ADD_FACT_TO_CONSTRUCT, {
                    construct: factAddingPayload.construct,
                    fact: factAddingPayload.fact,
                });
            }
        } catch (error) {
            this.dispatch(
                'storeUser/A_SET_BACK_ERROR',
                openBackErrorPopup('Не удалось оформить исполнительную документацию')
            );
            return Promise.reject(error);
        }
    },

    async [WorkListActions.A_TRANSFER_CONSTRUCTS](_, payload: ITransferPostData){
        try {
            const apiMethod = methods['works/set-parent']({
                appId: payload.appId,
                constructIds: payload.constructIds,
                structureId: payload.structureId,
            });
            await http.post(apiMethod.url, apiMethod.params);
        } catch (error) {
            this.dispatch(
                'storeUser/A_SET_BACK_ERROR',
                openBackErrorPopup(
                    `Не удалось переместить конструктив${
                        payload.constructIds.length > 1
                            ? 'ы'
                            : ''
                    } в структуру работ`
                )
            );
            return Promise.reject(error);
        }
    },

    async [WorkListActions.A_DELETE_WORK]({ commit, rootState }, work: IWork) {
        try {
            const apiMethod = methods['works/remove-structure'](
                {
                    appId: rootState.storeProject.project.id,
                    id: work.uuid,
                }
            );
            await http.post(apiMethod.url, apiMethod.params);
            commit(WorkListMutations.M_DELETE_WORK, work);
        } catch (error) {
            this.dispatch(
                'storeUser/A_SET_BACK_ERROR',
                openBackErrorPopup('Не удалось удалить структуру работ')
            );
            return Promise.reject(error);
        }
    },

    async [WorkListActions.A_SET_SEARCH_RESULTS_IDS_CHAIN]({ commit }, payload) {
        commit(WorkListMutations.M_SET_SEARCH_RESULTS_IDS_CHAIN, payload);
    },

    async [WorkListActions.A_SET_DRAGGING_STATE]({commit}, payload) {
        try {
            commit(WorkListMutations.M_SET_DRAGGING_STATE, payload);
        } catch (error) {
            return Promise.reject(error);
        }
    },

    async [WorkListActions.A_SET_VIEWING_WORK]({ commit, state, rootState }, structureId: string): Promise<any> {
        try {
            recursion(state.workList);
            // Находим просматриваемую работу в иерархии и перезаписываем
            function recursion(works) {
                for (const work of works) {
                    if (work.uuid === structureId) {
                        commit('M_SET_VIEWING_WORK', work);
                        return;
                    }
                    if (work.children && work.children.length) {
                        recursion(work.children);
                    }
                }
            }

        } catch (error) {
            return Promise.reject(error);
        }
    },

    async [WorkListActions.A_SET_PATH_TO_VIEWING_WORK]({ commit, state }, pathToViewingWork: Set<IWork>): Promise<any> {
        try {
            let newPathToViewingWork: Set<IWork> = new Set();
            recursion(state.workList);
            commit('M_SET_PATH_TO_VIEWING_WORK', newPathToViewingWork);
            // Находим путь в иерархии к просматриваемой работе и перезаписываем
            function recursion(works) {
                for (const work of works) {
                    for (let setWork of pathToViewingWork) {
                        if (work.uuid === setWork?.uuid) {
                            newPathToViewingWork.add(work);
                        }
                    }

                    if (work.children && work.children.length) {
                        recursion(work.children);
                    }
                }
            }
        } catch (error) {
            return Promise.reject(error);
        }
    },
}
