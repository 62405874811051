import { ActionTree } from 'vuex';
import { IRootState } from '@store/store';
import { IProjectTasksState, ITask } from './interfaces/Interfaces';
import { ICompleteTaskPostData, IReturnTaskPostData } from './interfaces/ApiRequests';
import { ProjectTasksActions, ProjectTasksMutations } from './Types';
import { AxiosResponse } from 'axios';
import http from '@/http';
import { methods } from '@/ones-api';
import { FormDataMaker } from '@utility/FormDataMaker';
import { IErrorsPopup } from "@store/modules/user/Interfaces";

const openBackErrorPopup = (errorText: string = ''): IErrorsPopup => {
    return {
        isOpen: true,
        errorText,
    };
};

export const actions: ActionTree<IProjectTasksState, IRootState> = {
    async [ProjectTasksActions.A_PROJECT_STORE_LISTENER]({ commit, dispatch }, projectId) {},

    async [ProjectTasksActions.A_SET_PROJECT_TASKS_LOADED]({ commit }, value) {
        commit(ProjectTasksMutations.M_SET_PROJECT_TASKS_LOADED, value);
    },

    async [ProjectTasksActions.A_GET_PROJECT_TASKS]({ commit }, projectId) {
        const apiMethod = await methods['tasks/list']({ appId: projectId });
        const { data }: AxiosResponse<ITask[]> = await http.get(apiMethod.url, { params: apiMethod.params });
        commit(ProjectTasksMutations.M_SET_PROJECT_TASKS, data);
    },

    async [ProjectTasksActions.A_POST_FORM_DATA](_, payload: { uri: string; data: any }) {
        try {
            const apiMethod = methods[payload.uri]();
            const formData = new FormDataMaker({ ...payload.data }).make();
            await http.post( apiMethod.url, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data; boundary=-----boundary---0',
                },
            } );
        } catch (error) {
            return Promise.reject(error);
        }
    },

    async [ProjectTasksActions.A_CREATE_NEW_TASK](_, payload) {
        const { postData, type } = payload;
        try {
            const apiMethod = methods['tasks/new-task'](postData);
            await http.post(apiMethod.url, apiMethod.params);
        } catch (error) {
            let text: string = 'подзадачу';
            if (type) {
                text = 'задачу';
            }
            this.dispatch(
                'storeUser/A_SET_BACK_ERROR',
                openBackErrorPopup(`Не удалось создать ${text}`)
            );
            return Promise.reject(error);
        }
    },

    async [ProjectTasksActions.A_COMPLETE_TASK](
        { dispatch },
        payload: { params: ICompleteTaskPostData, type?: string }
    ) {
        try {
            await dispatch(ProjectTasksActions.A_POST_FORM_DATA, {
                uri: 'tasks/perform-task',
                data: payload.params,
            });
        } catch (error) {
            let text: string = 'задачу';
            if (payload.type) {
                text = 'подзадачу';
            }
            this.dispatch(
                'storeUser/A_SET_BACK_ERROR',
                openBackErrorPopup(`Не удалось выполнить ${text}`)
            );
            return Promise.reject(error);
        }
    },

    async [ProjectTasksActions.A_TAKE_TASK_TO_WORK](
        { dispatch },
        payload: { params: ICompleteTaskPostData }
    ) {
        try {
            await dispatch(ProjectTasksActions.A_POST_FORM_DATA, {
                uri: 'tasks/take-task',
                data: payload.params,
            });
        } catch (error) {
            this.dispatch(
                'storeUser/A_SET_BACK_ERROR',
                openBackErrorPopup('Не удалось взять задачу на исполнение')
            );
            return Promise.reject(error);
        }
    },

    async [ProjectTasksActions.A_RETURN_TASK](
        { dispatch },
        payload: { params: IReturnTaskPostData }
    ) {
        try {
            dispatch(ProjectTasksActions.A_POST_FORM_DATA, {
                uri: 'tasks/return-task',
                data: payload.params,
            });
        } catch (error) {
            this.dispatch(
                'storeUser/A_SET_BACK_ERROR',
                openBackErrorPopup('Не удалось отправить задачу на доработку')
            );
            return Promise.reject(error);
        }
    },

    async [ProjectTasksActions.A_ACCEPT_TASK](
        { dispatch },
        payload: { params: IReturnTaskPostData }
    ) {
        try {
            await dispatch(ProjectTasksActions.A_POST_FORM_DATA, {
                uri: 'tasks/accept-task',
                data: payload.params,
            });
        } catch (error) {
            this.dispatch(
                'storeUser/A_SET_BACK_ERROR',
                openBackErrorPopup('Не удалось принять задачу')
            );
            return Promise.reject(error);
        }
    },

    async [ProjectTasksActions.A_DELETE_TASK](_, payload) {
        const { appId, ids, type } = payload;
        try {
            const apiMethod = methods['tasks/delete-task']({ appId, ids });
            await http.post(apiMethod.url, apiMethod.params);
        } catch (error) {
            let text: string = 'подзадачу';
            if (type) {
                text = 'задачу';
            }
            this.dispatch(
                'storeUser/A_SET_BACK_ERROR',
                openBackErrorPopup(`Не удалось удалить ${text}`)
            );
            return Promise.reject(error);
        }
    },

    async [ProjectTasksActions.A_CHECK_TASK](_, payload) {
        try {
            const formData = new FormDataMaker(payload).make();
            const apiMethod = methods['tasks/check-task'](formData);
            await http.post( apiMethod.url, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data; boundary=-----boundary---0',
                },
            } );
        } catch (error) {
            this.dispatch(
                'storeUser/A_SET_BACK_ERROR',
                openBackErrorPopup('Не удалось изменить статус подзадачи')
            );
            return Promise.reject(error);
        }
    },

    async [ProjectTasksActions.A_EDIT_TASK]({}, payload) {
        try {
            const apiMethod = methods['tasks/edit-task'](payload);
            await http.post(apiMethod.url, apiMethod.params);
        } catch (error) {
            this.dispatch(
                'storeUser/A_SET_BACK_ERROR',
                openBackErrorPopup('Не удалось редактировать задачу')
            );
            return Promise.reject(error);
        }
    },
}
