import { IProjectTask, IProjectTaskResponse } from '../Interfaces';

export class ProjectTasksTransformer {
    public toDomain(tasksResponse: IProjectTaskResponse[] | null): IProjectTask[] | null {
        try {
            const tasks = [] as IProjectTask[];

            if (!tasksResponse) {
                return null;
            } else {
                for (const task of tasksResponse) {
                    tasks.push({
                        id: task.id,
                        title: task.name,
                    });
                }
                return tasks;
            }

        }
        catch (error: any) {
            throw new Error(`Tasks Transformer Error: ${error.message ? error.message : ''}`);
        }
    }
    public toRequest() {}
}
