import { IFile, IFileResponse } from '@store/modules/project-documents/Interfaces';

export class FilesTransformer {
    public toDomain(filesResponse: IFileResponse[]): IFile[] {
        try {
            const files = [] as IFile[];
            for (const file of filesResponse) {
                files.push({
                    uuid: file.id,
                    title: file.name,
                    format: file.format,
                    size: file.size,
                    link: file.link,
                    linkView: file.view,
                    changeDate: file.changeDate || null,
                    changer: file.changer ? {
                        id: file.changer.id,
                        name: file.changer.name,
                    } : null,
                    signatures: file.signatures,
                });
            }

            return files;
        }
        catch (error: any) {
            throw new Error(`Files Transformer Error: ${error.message ? error.message : ''}`);
        }
    }
    public toRequest() {}
}
