import { IStatus, IStatusResponse } from "../Interfaces";

export class ProjectStatusesTransformer {
    public toDomain(statusesResponse: IStatusResponse[]): IStatus[] {
        try {
            const projectStatuses = []as IStatus[];
            for (const status of statusesResponse) {
                projectStatuses.push({
                    id: status.id,
                    title: status.name,
                });
            }

            return projectStatuses;
        }
        catch (error: any) {
            throw new Error(`Project Statuses Transformer Error: ${error.message ? error.message : ''}`);
        }
    }
    public toRequest() {}
}
