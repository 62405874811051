export enum ProjectTasksActions {
    A_PROJECT_STORE_LISTENER = 'A_PROJECT_STORE_LISTENER',
    A_GET_PROJECT_TASKS = 'A_GET_PROJECT_TASKS',
    A_POST_FORM_DATA = 'A_POST_FORM_DATA',
    A_CREATE_NEW_TASK = 'A_CREATE_NEW_TASK',
    A_COMPLETE_TASK = 'A_COMPLETE_TASK',
    A_RETURN_TASK = 'A_RETURN_TASK',
    A_ACCEPT_TASK = 'A_ACCEPT_TASK',
    A_TAKE_TASK_TO_WORK = 'A_TAKE_TASK_TO_WORK',
    A_DELETE_TASK = 'A_DELETE_TASK',
    A_CHECK_TASK = 'A_CHECK_TASK',
    A_EDIT_TASK = 'A_EDIT_TASK',
    A_SET_PROJECT_TASKS_LOADED = 'A_SET_PROJECT_TASKS_LOADED',
}

export enum ProjectTasksMutations {
    M_SET_PROJECT_TASKS_LOADED = 'M_SET_PROJECT_TASKS_LOADED',
    M_SET_PROJECT_TASKS = 'M_SET_PROJECT_TASKS',
    M_CHANGE_GROUPS_FILTER = 'M_CHANGE_GROUPS_FILTER',
    M_SET_TASKS_FILTERS = 'M_SET_TASKS_FILTERS',
    M_SET_TASKS_FILTERS_APPLY = 'M_SET_TASKS_FILTERS_APPLY',
    M_SET_TASKS_AFTER_CHANGE = 'M_SET_TASKS_AFTER_CHANGE',
}
