export const enum UserActionTypes {
    A_LOGIN = 'A_LOGIN',
    A_LOGOUT = 'A_LOGOUT',
    A_SET_LOGIN_DATA_TO_LOCAL_STORAGE = 'A_SET_LOGIN_DATA_TO_LOCAL_STORAGE',
    A_GET_USER = 'A_GET_USER',
    A_AUTH_USER = 'A_AUTH_USER',
    A_SET_FAVORITE = 'A_SET_FAVORITE',
    A_REMOVE_FAVORITE = 'A_REMOVE_FAVORITE',
    A_FETCH_TASKS = 'A_FETCH_TASKS',
    A_FETCH_COMPLETE = 'A_FETCH_COMPLETE',
    A_CREATE_NEW_TASK = 'A_CREATE_NEW_TASK',
    A_FINISH_TASK_OUT = 'A_FINISH_TASK_OUT',
    A_FINISH_TASK_IN = 'A_FINISH_TASK_IN',
    A_CHANGE_PROJECT = 'A_CHANGE_PROJECT',
    A_GET_ACCESS_USER_STATUSES = 'A_CHANGE_ACCESS_USER_STATUSES',
    A_USER_TOKEN = 'A_USER_TOKEN',
    A_REFRESH_TOKEN = 'A_REFRESH_TOKEN',
    A_COMPLETE_REGISTRATION = 'A_COMPLETE_REGISTRATION',
    A_REGISTRATION_INFO = 'A_REGISTRATION_INFO',
    A_SET_TOKEN_REFRESHING = 'A_SET_TOKEN_REFRESHING',
    A_SET_BACK_ERROR = 'A_SET_BACK_ERROR',
    A_SET_USER_RIGHT_ONLY_GSN = 'A_SET_USER_RIGHT_ONLY_GSN',
    A_CHANGE_PASSWORD = 'A_CHANGE_PASSWORD',
    A_EMAIL_CONFIRM = 'A_EMAIL_CONFIRM',
    A_FORGOT_PASSWORD = 'A_FORGOT_PASSWORD',
    A_RESET_PASSWORD = 'A_RESET_PASSWORD',
    A_ERROR_HANDLER = 'A_ERROR_HANDLER',
}

export const enum UserMutationTypes {
    M_LOGIN = 'M_LOGIN',
    M_LOGOUT = 'M_LOGOUT',
    M_AUTH_USER = 'M_AUTH_USER',
    M_SET_FAVORITE = 'M_SET_FAVORITE',
    M_FETCH_USER = 'M_FETCH_USER',
    M_FETCH_TASKS = 'M_FETCH_TASKS',
    M_SELECT_PROJECT = 'M_SELECT_PROJECT',
    M_UPDATE_PROJECT = 'M_UPDATE_PROJECT',
    M_FETCH_COMPLETE = 'M_FETCH_COMPLETE',
    M_ADD_TASK = 'M_ADD_TASK',
    M_FINISH_TASK = 'M_FINISH_TASK',
    M_CLEAR_TASK = 'M_CLEAR_TASK',
    M_CHANGE_ACCESS_USER_STATUSES = 'M_CHANGE_ACCESS_USER_STATUSES',
    M_USER_TOKEN = 'M_USER_TOKEN',
    M_SET_TOKEN_REFRESHING = 'M_SET_TOKEN_REFRESHING',
    M_SET_BACK_ERROR = 'M_SET_BACK_ERROR',
    M_SET_USER_RIGHT_ONLY_GSN = 'M_SET_USER_RIGHT_ONLY_GSN',
}
