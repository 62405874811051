import { ActionTree } from 'vuex';
import { IRootState } from '@store/store';
import { IProjectRespondsState } from './interfaces/Interfaces';
import { ProjectRespondsActions, ProjectRespondsMutations } from './Types';
import http from '@/http';
import { smarteApi } from '@/smarte-api';

export const actions: ActionTree<IProjectRespondsState, IRootState> = {
    async [ProjectRespondsActions.A_PROJECT_STORE_LISTENER]({}, projectId) {},

    async [ProjectRespondsActions.A_GET_PROJECT_ALL_ORDERS]({ commit }, projectId) {
        try {
            if (projectId) {
                const smarteApiUrl = smarteApi['smarte/orders/all']({ projectId });
                const { data } = await http.get(smarteApiUrl);
                commit(ProjectRespondsMutations.M_SET_PROJECT_RESPONDS, data.orders);
                commit(ProjectRespondsMutations.M_SET_RESPONDS_LOADED);
            }
        } catch (error) {
            return Promise.reject(error);
        }
    },
}
