import { MutationTree } from 'vuex';
import { IProjectTasksState, ITask } from './interfaces/Interfaces';
import { ITaskResponse } from './interfaces/ApiResponses';
import { ProjectTasksMutations } from './Types';
import { TasksTransformer } from './transformers/TasksTransformer';

export const mutations: MutationTree<IProjectTasksState> = {
    [ProjectTasksMutations.M_SET_PROJECT_TASKS_LOADED](state, loadedState) {
        state.projectTasksLoaded = loadedState;
    },

    [ProjectTasksMutations.M_SET_PROJECT_TASKS](state, tasks: ITaskResponse[]) {
        const tasksTransformer = new TasksTransformer();
        state.tasks = tasksTransformer.toDomain(tasks);
    },

    [ProjectTasksMutations.M_CHANGE_GROUPS_FILTER](state, filterValue: 'all' | 'new' | 'assign' | 'execute' | 'done') {
        state.groupsFilter = filterValue;
    },


    [ProjectTasksMutations.M_SET_TASKS_FILTERS](state, val: any[]) {
        state.filters = val;
    },

    [ProjectTasksMutations.M_SET_TASKS_FILTERS_APPLY](state, val: boolean) {
        state.filtersApply = val;
    },

    [ProjectTasksMutations.M_SET_TASKS_AFTER_CHANGE](state, data: { any: ITask}) {
        state.tasks.forEach((stateTask) => {
            if (data[stateTask.uuid]) {
                const task = data[stateTask.uuid];
                stateTask.deadline = task.deadline;
                stateTask.executor = {
                    fullName: task.executor.label,
                    uuid: task.executor.uuid,
                };
                stateTask.title = task.title;
            }
        })
    },
}
