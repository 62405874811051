import { IPackPD, IPackPDResponse } from '../Interfaces';
import { INameAndUuid } from '@/interfaces/INameAndUuid';
import { PackEDTransformer } from './PackEDTransformer';
export interface IKitBrand extends INameAndUuid {}

const packEDTransformer = new PackEDTransformer();

export class PackPDTransformer {
    public toDomain(statusesResponse: IPackPDResponse[]): IPackPD[] {
        try {
            const items = [] as IPackPD[];
            for (const item of statusesResponse) {
                let itemKitBrand:IKitBrand | null = null;
                if (item.kitBrand)  {
                    itemKitBrand = {
                        uuid: item.kitBrand.id,
                        title: item.kitBrand.name,
                    }
                }
                items.push({
                    uuid: item.id,
                    title: item.cypher,
                    kitBrand: itemKitBrand,
                    packED: packEDTransformer.toDomain(item.packED),
                });
            }
            return items;
        }
        catch (error: any) {
            throw new Error(`Pack DP Transformer Error: ${error.message ? error.message : ''}`);
        }
    }
    public toRequest() {}
}
