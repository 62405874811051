import { Module } from 'vuex';
import { getters } from './Getters';
import { actions } from './Actions';
import { mutations } from './Mutations';
import { IRootState } from '@store/store';
import { IProjectTeamState, IMemberInfo } from './interfaces/Interfaces';
import { storeProjectTeamPopups } from './modules/popups'

const state = {
    DEPENDS_ON_PROJECT_STORE: true,
    projectTeamLoaded: false,
    projectTeam: {
        daconMembers: [],
        smartcomMembers: [],
        allMembers: [],
    },
    viewedMember: {} as IMemberInfo,
    memberRights: [],
    filters: [],
    filtersApply: false,
    currentViewMode: null,
} as IProjectTeamState

export const storeProjectTeam = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
    modules: { storeProjectTeamPopups },
} as Module<IProjectTeamState, IRootState>
