import Vue from 'vue'
import Vuex from 'vuex'

import { storeUser } from './modules/user';
import { IUserState } from './modules/user/Interfaces';

import { storeUserProjects } from './modules/user-projects';
import { IUserProjectsState } from './modules/user-projects/Interfaces';

import { storeProject } from './modules/project';
import { IProjectState } from './modules/project/Interfaces';

import { storeProjectTeam } from './modules/project-team';
import { IProjectTeamState } from './modules/project-team/interfaces/Interfaces';

import { storeSidebar } from './modules/sidebar';
import { ISidebarState } from './modules/sidebar/Interface';

import { storeProjectDocuments } from './modules/project-documents';
import { IDocumentsState } from './modules/project-documents/Interfaces';

import { storeWorkList } from './modules/work-list';
import { IWorkListState } from './modules/work-list/interfaces/Interfaces';

import { storeProjectTasks } from './modules/project-tasks';
import { IProjectTasksState } from './modules/project-tasks/interfaces/Interfaces';

import { storeAboutProject } from './modules/about-project';
import { IAboutProject } from './modules/about-project/Interfaces';

import { storeProjectResponds } from './modules/project-responds';
import { IProjectRespondsState } from './modules/project-responds/interfaces/Interfaces';

import { storeInputControl } from './modules/input-control';
import { IInputControl } from './modules/input-control/Interfaces';

Vue.use(Vuex)

export interface IRootState {
    storeUser: IUserState;
    storeUserProjects: IUserProjectsState;
    storeProject: IProjectState;
    storeProjectTeam: IProjectTeamState;
    storeSidebar: ISidebarState;
    storeProjectDocuments: IDocumentsState;
    storeWorkList: IWorkListState;
    storeProjectTasks: IProjectTasksState;
    storeAboutProject: IAboutProject;
    storeProjectResponds: IProjectRespondsState;
    storeInputControl: IInputControl;
}

export default new Vuex.Store<IRootState>({
    modules: {
        storeUser,
        storeUserProjects,
        storeProject,
        storeProjectTeam,
        storeSidebar,
        storeProjectDocuments,
        storeWorkList,
        storeProjectTasks,
        storeAboutProject,
        storeProjectResponds,
        storeInputControl,
    },
})
