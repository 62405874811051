export const enum WorkListActions {
    A_PROJECT_STORE_LISTENER = 'A_PROJECT_STORE_LISTENER',
    A_GET_WORK_LIST = 'A_GET_WORK_LIST',
    A_GROUP_CONSTRUCTS_BY_PACKS = 'A_GROUP_CONSTRUCTS_BY_PACKS',
    A_ADD_NEW_WORK = 'A_ADD_NEW_WORK',
    A_SET_ACTION_ON_CONSTRUCT = 'A_SET_ACTION_ON_CONSTRUCT',
    A_ADD_FACT_TO_CONSTRUCT = 'A_ADD_FACT_TO_CONSTRUCT',
    A_TRANSFER_CONSTRUCTS = 'A_TRANSFER_CONSTRUCTS',
    A_DELETE_WORK = 'A_DELETE_WORK',
    A_SET_SEARCH_RESULTS_IDS_CHAIN = 'A_SET_SEARCH_RESULTS_IDS_CHAIN',
    A_SET_DRAGGING_STATE = 'A_SET_DRAGGING_STATE',
    A_SET_WORK_LIST_LOADED = 'A_SET_WORK_LIST_LOADED',
    A_SET_VIEWING_WORK = 'A_SET_VIEWING_WORK',
    A_SET_PATH_TO_VIEWING_WORK = 'A_SET_PATH_TO_VIEWING_WORK',
}
export const enum WorkListMutations {
    M_SET_WORK_LIST_LOADED = 'M_SET_WORK_LIST_LOADED',
    M_SET_WORK_LIST = 'M_SET_WORK_LIST',
    M_GROUP_CONSTRUCT_BY_PACKS = 'M_GROUP_CONSTRUCT_BY_PACKS',
    M_SET_VIEWING_WORK = 'M_SET_VIEWING_WORK',
    M_SET_VIEWING_WORK_TITLE = 'M_SET_VIEWING_WORK_TITLE',
    M_SET_VIEWING_PACK = 'M_SET_VIEWING_PACK',
    M_SET_VIEWING_PACK_EXECUTIVE = 'M_SET_VIEWING_PACK_EXECUTIVE',
    M_SET_VIEWING_PACK_TITLE = 'M_SET_VIEWING_PACK_TITLE',
    M_SET_VIEWING_DOCUMENT = 'M_SET_VIEWING_DOCUMENT',
    M_TOGGLE_CONSTRUCT_SELECTION = 'M_TOGGLE_CONSTRUCT_SELECTION',
    M_SWITCH_CONSTRUCTS_GROUPING = 'M_SWITCH_CONSTRUCTS_GROUPING',
    M_ADD_NEW_WORK = 'M_ADD_NEW_WORK',
    M_SET_ACTION_ON_CONSTRUCT = 'M_SET_ACTION_ON_CONSTRUCT',
    M_ADD_FACT_TO_CONSTRUCT = 'M_ADD_FACT_TO_CONSTRUCT',
    M_SET_ADDING_FACT_CONSTRUCT_ID = 'M_SET_ADDING_FACT_CONSTRUCT_ID',
    M_DELETE_WORK = 'M_DELETE_WORK',
    M_SET_FILTERS = 'M_SET_FILTERS',
    M_SET_FILTERS_APPLY = 'M_SET_FILTERS_APPLY',
    M_UNSET_CONSTRUCT_PACKS = 'M_UNSET_CONSTRUCT_PACKS',
    M_SET_SEARCH_RESULTS_IDS_CHAIN = 'M_SET_SEARCH_RESULTS_IDS_CHAIN',
    M_SET_DRAGGING_STATE = 'M_SET_DRAGGING_STATE',
    M_SET_PATH_TO_VIEWING_WORK = 'M_SET_PATH_TO_VIEWING_WORK',
}
