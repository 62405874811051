import { IDocument, IDocumentResponse } from '@store/modules/project-documents/Interfaces';
import { PersonTransformer } from './PersonTransformer';
import { StatusTransformer } from './StatusTransformer';

export class DocumentsTransformer {
    public toDomain(documentsResponse: IDocumentResponse[]): IDocument[] {
        const personTransformer = new PersonTransformer();
        const statusTransformer = new StatusTransformer();

        const documents = [] as IDocument[];
        for (const document of documentsResponse) {
            documents.push({
                uuid: document.id,
                title: document.fullName || document.name,
                category: document.category,
                kitBrand: document.kitBrand,
                version: document.version,
                description: document.description,
                author: personTransformer.toDomain(document.author),
                changer: personTransformer.toDomain(document.changer),
                createDate: document.createDate,
                changeDate: document.changeDate,
                files: [],
                status: document.status ? statusTransformer.toDomain(document.status) : null,
                isFolder: document.isFolder,
                children: document.children && document.children.length ? this.toDomain(document.children) : [],
                type: document.type,
                signed: document.signed,
            });
        }

        return documents;
    }
}
