import { Module } from 'vuex';
import { actions } from './Actions';
import { mutations } from './Mutations';
import { getters } from './Getters';
import { IRootState } from '@store/store';
import { IUserProjectsState } from './Interfaces';

const state = {
    projects: [],
    projectStatuses: [],
    allUsers: {
        page: Infinity,
        pages: Infinity,
        users: [],
    },
    views: [
        { value: 'active', label: 'Активные проекты' },
        { value: 'archive', label: 'Архив проектов' },
        { value: 'users', label: 'Пользователи' },
    ],
    currentView: '',
    filters: [],
    filtersApply: false,
    viewsMode: [
        'tiles',
        'grid',
        'simple',
    ],
    currentViewMode: '',
    userProjectsGetting: false,
}

export const storeUserProjects = {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
} as Module<IUserProjectsState, IRootState>
