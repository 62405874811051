export enum ProjectTeamActions {
    A_PROJECT_STORE_LISTENER = 'A_PROJECT_STORE_LISTENER',
    A_ENRICH_MEMBERS_INFO = 'A_ENRICH_MEMBERS_INFO',
    A_ENRICH_MEMBER_INFO = 'A_ENRICH_MEMBER_INFO',
    A_GET_MEMBER_RIGHTS = 'A_GET_MEMBER_RIGHTS',
    A_SET_VIEWED_MEMBER = 'A_SET_VIEWED_MEMBER',
    A_FIND_MEMBER = 'A_FIND_MEMBER',
    A_INVITE_MEMBER = 'A_INVITE_MEMBER',
    A_ADD_RIGHT_TO_MEMBER = 'A_ADD_RIGHT_TO_MEMBER',
    A_DELETE_MEMBER_RIGHT = 'A_DELETE_MEMBER_RIGHT',
    A_APPLY_ROLE_TO_MEMBER = 'A_APPLY_ROLE_TO_MEMBER',
    A_APPLY_ROLE_TO_SELECTED = 'M_APPLY_ROLE_TO_SELECTED',
    A_APPLY_ROLE_TO_VIEWED_MEMBER = 'A_APPLY_ROLE_TO_VIEWED_MEMBER',
    A_CHANGE_MEMBER_DOC_STATUSES = 'A_CHANGE_MEMBER_DOC_STATUSES',
    A_EDIT_PROJECT_MEMBER = 'A_EDIT_PROJECT_MEMBER',
    A_DEACTIVATE_MEMBER = 'A_DEACTIVATE_MEMBER',
}

export enum ProjectTeamMutations {
    M_SET_PROJECT_TEAM_LOADED = 'M_SET_PROJECT_TEAM_LOADED',
    M_SET_PROJECT_TEAM = 'M_SET_PROJECT_TEAM',
    M_SET_MEMBER_TEAM = 'M_SET_MEMBER_TEAM',
    M_SET_MEMBER_RIGHTS = 'M_SET_MEMBER_RIGHTS',
    M_SET_VIEWED_MEMBER = 'M_SET_VIEWED_MEMBER',
    M_ADD_MEMBER_RIGHT = 'M_ADD_MEMBER_RIGHT',
    M_DELETE_MEMBER_RIGHT = 'M_DELETE_MEMBER_RIGHT',
    M_APPLY_ROLE_TO_SELECTED = 'M_APPLY_ROLE_TO_SELECTED',
    M_APPLY_ROLE_TO_VIEWED_MEMBER = 'M_APPLY_ROLE_TO_VIEWED_MEMBER',
    M_CHANGE_MEMBER_DOC_STATUSES = 'M_CHANGE_MEMBER_DOC_STATUSES',
    M_SET_FILTERS = 'M_SET_FILTERS',
    M_SET_FILTERS_APPLY = 'M_SET_FILTERS_APPLY',
    M_SET_CURRENT_VIEW_MODE = 'M_SET_CURRENT_VIEW_MODE',
}
