import {IMarkKit, IMarkKitResponse} from '../Interfaces';

export class MarkKitTransformer {
    public toDomain(markKitResponse: IMarkKitResponse[]): IMarkKit[] {
        try {
            const markKits = [] as IMarkKit[];
            for (const item of markKitResponse) {
                markKits.push({
                    id: item?.id,
                    title: item?.name,
                    type: item?.type,
                })
            }
            return markKits;
        }
        catch (error: any) {
            throw new Error(`Mark kit Transformer Error: ${error.message ? error.message : ''}`);
        }
    }
    public toRequest() {}
}
