import { IStatus, IStatusResponse } from '../Interfaces';

export class StatusTransformer {
    public toDomain(statusesResponse: IStatusResponse[]): IStatus[] {
        try {
            const statuses = [] as IStatus[];
            for (const status of statusesResponse) {
                statuses.push({
                    id: status.id,
                    title: status.name,
                    blocked: status.blocked,
                    color: status.color,
                });
            }
            return statuses;
        }
        catch (error: any) {
            throw new Error(`Status Transformer Error: ${error.message ? error.message : ''}`);
        }
    }
    public toRequest() {}
}
