import { namespace } from 'vuex-class';
import { Module } from 'vuex';
import { IUserState } from './Interfaces';
import { IRootState } from '../../store';
import { actions } from './Actions';
import { mutations } from './Mutations';
import { getters } from './Getters';

export const userNamespace = namespace('storeUser');

class UserState implements IUserState {
    isAuthUser = false;
    userToken = null;
    tokenRefreshing = false;
    userInfo = {
        id: '',
        fullName: '',
        login: '',
        fromSmartcom: null,
        email: '',
        phone: '',
        favorites: [],
        portalPart: true,
        engineerPart: true,
    }
    backError = {
        isOpen: false,
        errorText: '',
    }
    userRightOnlyGSN = false;
}

export const storeUser: any = {
    namespaced: true,
    state: () => new UserState(),
    getters,
    actions,
    mutations,
} as Module<IUserState, IRootState>;
