































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { UserActionTypes } from '@store/modules/user/Types';
import { IUser, IErrorsPopup } from '@store/modules/user/Interfaces';
import { UserProjectsActions, UserProjectsMutations } from '@store/modules/user-projects/Types';
import { namespace } from 'vuex-class';
import { ProjectActions } from '@store/modules/project/Types';
import { ProjectTasksActions } from '@store/modules/project-tasks/Types';
import { ProjectTeamActions } from '@store/modules/project-team/Types';
import { WorkListActions, WorkListMutations } from '@store/modules/work-list/Types';
import { InputControlActionTypes } from '@store/modules/input-control/Types';

const NSUserProjects = namespace('storeUserProjects');
const NSProject = namespace('storeProject');
const NSProjectTasks = namespace('storeProjectTasks');
const NSProjectTeam = namespace('storeProjectTeam');
const NSWorkList = namespace('storeWorkList');
const NSInputControl = namespace('storeInputControl');
const NSUser = namespace('storeUser');
const NSDocuments = namespace('storeProjectDocuments');

@Component({
    components: {
        BasePreloader: () => import('@components/BasePreloader/BasePreloader.vue'),
        TheSidebar: () => import('./components/BaseSidebar/BaseSidebar.vue'),
        TheSprites: () => import('./components/BaseSprites.vue'),
        TheHeader: () => import('@components/BaseHeader/BaseHeader.vue'),
        ButtonScrollUp: () => import('@components/buttons/ButtonScrollUp.vue'),
        PopupConfirmation: () => import('@components/popups/PopupConfirmation.vue'),
    },
})
export default class App extends Vue {
    @NSUser.State('userToken') token!: string
    @NSUser.Action(UserActionTypes.A_USER_TOKEN) fetchToken!: (token) => Promise<any>
    @NSUser.Action(UserActionTypes.A_AUTH_USER) authUser!: (auth: boolean) => Promise<void>
    @NSUser.Action(UserActionTypes.A_LOGIN) login!: (payload) => Promise<IUser>
    @NSUser.Action(UserActionTypes.A_REFRESH_TOKEN) refreshToken!: () => Promise<IUser>
    @NSUser.Action(UserActionTypes.A_SET_BACK_ERROR) setBackError!: (payload: IErrorsPopup) => void;
    @NSUser.Getter('backError') backError!: IErrorsPopup;
    @NSUserProjects.Action(UserProjectsActions.A_GET_USER_PROJECTS) getUserProject!: () => Promise<void>;
    @NSUserProjects.Action(UserProjectsActions.A_GET_PROJECT_STATUSES) getProjectStatuses!: () => Promise<void>;
    @NSUserProjects.Action(UserProjectsActions.A_GET_ALL_USERS) getAllUsers!: () => Promise<void>;
    @NSUserProjects.Mutation(UserProjectsMutations.M_SET_USER_PROJECTS_GETTING) setUserProjectGetting!: (boolean) => void;
    @NSProject.Action(ProjectActions.A_GET_PROJECT) reloadDocuments!: (any) => Promise<void>
    @NSProjectTasks.Action(ProjectTasksActions.A_GET_PROJECT_TASKS) getProjectTasks!: (projectId: string | number) => Promise<void>;
    @NSProjectTeam.Action(ProjectTeamActions.A_ENRICH_MEMBERS_INFO) enrichMembersInfo!: (projectId: string | number) => Promise<void>;
    @NSWorkList.Action(WorkListActions.A_GET_WORK_LIST) getWorkList!: (projectId: string | number) => Promise<void>;
    @NSWorkList.Mutation(WorkListMutations.M_SET_WORK_LIST_LOADED) setWorkListLoaded!: (loadedState: boolean) => void;
    @NSInputControl.Action(InputControlActionTypes.A_GET_INPUT_CONTROL_DOCUMENTS) getInputControl!: (projectId: string | number) => Promise<void>;

    popupErrorsShow: boolean = false;
    routersAuth: string[] = [
        'auth',
        'registration',
        'forgot-password',
        'reset-password',
    ]

    mounted() {
        setTimeout(() => {
            this.setAuth();
        }, 500);
    }

    get isNotAuthPage() {
        return !this.routersAuth.includes(this.$route.name ? this.$route.name : '');
    }

    setAuth() {
        this.fetchToken(localStorage.getItem('token') === '' ? null : localStorage.getItem('token'))
        this.login({ appId: parseInt(this.$route.params.projectId, 10) })
        .then(() => {
            if (this.token.length && localStorage.getItem('authUserId')) {
                this.authUser(true);
            }
        })
        .catch(() => {
            if (localStorage.getItem('authUserId')) {
                this.refreshToken().then(() => {
                    this.setAuth();
                    setTimeout(() => {
                        this.setUserProjectGetting(true);
                        this.getUserProject().then(() => {
                            this.getProjectsData();
                            this.setUserProjectGetting(false);
                        });

                        if (this.$route.name === 'tasks' && this.$route.params && this.$route.params.projectId) {
                            this.getProjectTasks(this.$route.params.projectId);
                        }
                        if (this.$route.name === 'project-team' && this.$route.params && this.$route.params.projectId) {
                            this.reloadDocuments({ projectId: this.$route.params.projectId });
                            this.enrichMembersInfo(this.$route.params.projectId);
                        }
                        if (this.$route.name === 'about-project' && this.$route.params && this.$route.params.projectId) {
                            this.reloadDocuments({ projectId: this.$route.params.projectId });
                        }
                        if (this.$route.name === 'work-list' && this.$route.params && this.$route.params.projectId) {
                            this.getWorkList(this.$route.params.projectId).finally(() => {
                                this.setWorkListLoaded(true);
                            });
                        }
                        if (this.$route.name === 'input-control' && this.$route.params && this.$route.params.projectId) {
                            this.getInputControl(this.$route.params.projectId);
                        }
                    }, 1);
                }).catch(() => {
                    this.$router.push({ path: '/auth' });
                });
            }
        })
    }

    async getProjectsData() {
        await Promise.all([
            this.getProjectStatuses(),
            this.getAllUsers(),
        ]);
    }
}
