import { MutationTree } from 'vuex';
import {
    IWorkListState,
    IWork,
    IConstruct,
    IConstructDocument,
} from './interfaces/Interfaces';
import { WorkListMutations } from './Types';
import { WorkListTransformer } from './transformers/WorkListTransformer';
import { IWorkResponse } from './interfaces/ApiResponses';

export const mutations: MutationTree<IWorkListState> = {
    [WorkListMutations.M_SET_WORK_LIST_LOADED](state, loadedState: boolean) {
        state.workListLoaded = loadedState;
    },

    [WorkListMutations.M_SET_WORK_LIST](state, workListResponse: IWorkResponse[]) {
        const workListTransformer = new WorkListTransformer();
        state.workList = workListTransformer.toDomain(workListResponse);
    },

    [WorkListMutations.M_GROUP_CONSTRUCT_BY_PACKS](state, constructs: IConstruct[]) {
        for (const constructItem of constructs) {
            if (state.constructPacks[constructItem.dockPack.title] === undefined) {
                state.constructPacks[constructItem.dockPack.title] = [];
                state.constructPacks[constructItem.dockPack.title].push(constructItem);
                continue;
            }
            state.constructPacks[constructItem.dockPack.title].push(constructItem);
        }
    },

    [WorkListMutations.M_UNSET_CONSTRUCT_PACKS](state) {
        state.constructPacks = {};
    },

    [WorkListMutations.M_SET_VIEWING_WORK](state, work: IWork) {
        state.viewingWork = work;
    },

    [WorkListMutations.M_SET_PATH_TO_VIEWING_WORK](state, pathToViewingWork: Set<IWork>) {
        state.pathToViewingWork = pathToViewingWork;
    },

    [WorkListMutations.M_SET_VIEWING_WORK_TITLE](state, work: IWork) {
        state.viewingWorkTitle = work.title;
    },

    [WorkListMutations.M_SET_VIEWING_PACK](state, packKey: string) {
        state.viewingPack = state.constructPacks[packKey];
    },

    [WorkListMutations.M_SET_VIEWING_PACK_EXECUTIVE](state, packKey: string) {
        state.viewingPackExecutive = packKey;
    },

    [WorkListMutations.M_SET_VIEWING_PACK_TITLE](state, packKey: string) {
        state.viewingPackTitle = packKey;
    },

    [WorkListMutations.M_SET_VIEWING_DOCUMENT](state, document: IConstructDocument) {
        state.viewingDocument = document;
    },

    [WorkListMutations.M_TOGGLE_CONSTRUCT_SELECTION](_, construct: IConstruct) {
        construct.selected = !construct.selected;
    },

    [WorkListMutations.M_SWITCH_CONSTRUCTS_GROUPING](state, groupingType: 'composite' | 'packs') {
        state.constructsGrouping = groupingType;
    },

    [WorkListMutations.M_ADD_NEW_WORK](_, workAddingPayload: {
        workToAddInto: IWork[],
        workToAdd: IWork,
        isRootWorkListSelected: boolean,
    }) {
        if (workAddingPayload.isRootWorkListSelected) {
            workAddingPayload.workToAddInto.unshift(workAddingPayload.workToAdd);
        } else {
            workAddingPayload.workToAddInto.push(workAddingPayload.workToAdd);
        }
    },

    [WorkListMutations.M_SET_ACTION_ON_CONSTRUCT](state, construct) {
        state.actionOnConstruct = construct;
    },

    [WorkListMutations.M_ADD_FACT_TO_CONSTRUCT](
        _,
        factAddingPayload: {
            construct: IConstruct;
            fact: number;
        },
    )
    {
        factAddingPayload.construct.fact += factAddingPayload.fact;
    },

    [WorkListMutations.M_DELETE_WORK](state, workToDelete: IWork) {
        function recursion(array) {
            const output: any = [];
            let index = 0;

            for (let i = 0; i < array.length; i++) {
                if (array[i].uuid !== workToDelete.uuid) {
                    output.push(array[i]);

                    if (output[index].children && output[index].children.length) {
                        output[index].children = recursion(array[index].children);
                    }
                    if (output[index].constructs && output[index].constructs.length) {
                        output[index].constructs = recursion(array[index].constructs);
                    }
                    index++;
                }
            }
            return output;
        }
        state.workList = recursion(state.workList);
    },

    [WorkListMutations.M_SET_FILTERS](state, val: any[]) {
        state.filters = val;
    },

    [WorkListMutations.M_SET_FILTERS_APPLY](state, val: boolean) {
        state.filtersApply = val;
    },

    [WorkListMutations.M_SET_SEARCH_RESULTS_IDS_CHAIN](state, val) {
        state.searchResultIdsChain = val;
    },

    [WorkListMutations.M_SET_ADDING_FACT_CONSTRUCT_ID](state, id) {
        state.addingExecutiveDocumentConstructId = id;
    },

    [WorkListMutations.M_SET_DRAGGING_STATE](state, val) {
        state.draggingState = val;
    },
}
