import { GetterTree } from 'vuex';
import { IProjectState } from './Interfaces';
import { IRootState } from '../../store';

export const getters: GetterTree<IProjectState, IRootState> = {
    projectData: (state) => state.project,
    projectMarkKits: (state) => state.projectMarkKits,
    projectId: (state) => state.project.id,
    memberRoles: (state) => state.memberRoles,
    memberRolesJuristic: (state) => state.memberRolesJuristic,
    searchResult: (state) => state.searchResult,
    searchingProcess: (state) => state.searchingProcess,
};
