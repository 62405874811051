import {ICategory, ICategoryResponse} from '../Interfaces';

export class CategoryListTransformer {
    public toDomain(categoryResponse: ICategoryResponse[]): ICategory[] {
        try {
            const categories = [] as ICategory[];
            for (const item of categoryResponse) {
                categories.push({
                    id: item?.id,
                    title: item?.name,
                })
            }
            return categories;
        }
        catch (error: any) {
            throw new Error(`Category list Transformer Error: ${error.message ? error.message : ''}`);
        }
    }
    public toRequest() {}
}
