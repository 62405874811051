import { IMemberRole } from "../Interfaces";
import { IMemberRoleResponse } from "@store/modules/project/Interfaces";

export class MemberRolesTransformer {
    public toDomain(memberRolesResponse: IMemberRoleResponse[]) {
        const memberRoles = [] as IMemberRole[];
        for (const roleResponse of memberRolesResponse) {
            memberRoles.push({
                uuid: roleResponse.id,
                label: roleResponse.name,
                value: roleResponse.name,
            });
        }

        return memberRoles;
    }
    public toRequest() {}
}
