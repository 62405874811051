import { MutationTree } from 'vuex';
import { IAboutProject, IJuristicEntityResponse } from './Interfaces';
import { AboutProjectMutations } from './Types';
import { JuristicEntityTransformer } from "../project/transformers/JuristicEntitiesTransformer";

const juristicEntityTransformer = new JuristicEntityTransformer();

export const mutations: MutationTree<IAboutProject> = {
    [AboutProjectMutations.M_SET_ABOUT_PROJECT_LOADED](state, value: boolean) {
        state.aboutProjectLoaded = value;
    },
    [AboutProjectMutations.M_SET_INDIVIDUAL_MEMBERS_ABOUT_PROJECT](state, value) {
        state.individualMembers = value;
    },
    [AboutProjectMutations.M_SET_JURISTIC_MEMBERS_ABOUT_PROJECT](state, value) {
        state.juristicMembers = value;
    },
    [AboutProjectMutations.M_SET_FILTERS](state, value) {
        state.filters = value;
    },
    [AboutProjectMutations.M_SET_FILTERS_APPLY](state, value) {
        state.filtersApply = value;
    },
    [AboutProjectMutations.M_SET_JURISTIC_ENTITIES](state, entities: IJuristicEntityResponse[]) {
        state.juristicEntities =  juristicEntityTransformer.toDomain(entities);
    },
};
