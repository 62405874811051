import { MutationTree } from 'vuex';
import { IInputControl, IInputControlDocumentResponse } from './Interfaces';
import { InputControlMutationTypes } from './Types';

import { DocumentsTransformer } from './transformers/DocumentsTransformer'

const documentsTransformer = new DocumentsTransformer();

export const mutations: MutationTree<IInputControl> = {
    [InputControlMutationTypes.M_SET_INPUT_CONTROL_DOCUMENTS](state, documentsResponse: IInputControlDocumentResponse[]) {
        state.inputControlDocuments = documentsTransformer.toDomain(documentsResponse);
    },
    [InputControlMutationTypes.M_SET_INPUT_CONTROL_LOADED](state, val) {
        state.inputControlLoaded = val;
    },
};

