import { GetterTree } from 'vuex';
import { IProjectTasksState, ITask, ITaskGroup } from './interfaces/Interfaces';
import { IRootState } from '@store/store';

export const getters: GetterTree<IProjectTasksState, IRootState> = {
    userUuid(_, __, rootState): string {
        return rootState.storeUser.userInfo.id;
    },

    taskGroups(_, getters): ITaskGroup[] {
        return [
            {
                title: 'Новые',
                name: 'new',
                tasks: getters.newTasks,
            },
            {
                title: 'Поручил',
                name: 'assign',
                tasks: getters.tasksAssignedByUser,
            },
            {
                title: 'Исполняю',
                name: 'execute',
                tasks: getters.tasksExecutedByUser,
            },
            {
                title: 'Завершенные задачи',
                name: 'done',
                tasks: getters.completedTasks,
            },
        ];
    },

    newTasks(state): ITask[] {
        return state.tasks.filter((task) => task.state.name === 'Новое');
    },

    tasksAssignedByUser(state, getters): ITask[] {
        const output: ITask[] = [];
        state.tasks.forEach(task => {
            if (
                task.state.name === 'На проверке'
                && task.executor?.uuid === getters.userUuid
                && task.author?.uuid !== getters.userUuid
                || task.state.name === 'В работе'
                && task.executor?.uuid !== getters.userUuid
                && task.author?.uuid === getters.userUuid
            ) {
                output.push(task);
            }
        });
        return output;
    },

    tasksExecutedByUser(state, getters): ITask[] {
        const output: ITask[] = [];
        state.tasks.forEach(task => {
            if (
                task.state.name === 'В работе'
                && task.executor?.uuid === getters.userUuid
                || task.state.name === 'На проверке'
                && task.author.uuid === getters.userUuid
            ) {
                output.push(task);
            }
        });
        return output;
    },

    completedTasks(state): ITask[] {
        return state.tasks.filter((task) => task.state.name === 'Выполнено');
    },
    groupsFilter: (state) => {
        return state.groupsFilter;
    },
    filters: (state) => {
        return state.filters;
    },
    filtersApply: (state) => {
        return state.filtersApply;
    },
    tasks: (state) => {
        return state.tasks;
    },
    unfinishedTasks: (state): ITask[] => {
        return state.tasks.filter((task: ITask) => task.state.name !== 'Выполнено');
    },
    inputControlDocumentTasks: (state): ITask[] => {
        return state.tasks.filter((task: ITask) => task.subject && task.subject.systemType === 'Материалы');
    },
}
