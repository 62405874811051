import { IJuristicEntity, IJuristicEntityResponse } from "@store/modules/about-project/Interfaces";

export class JuristicEntityTransformer {
    public toDomain(juristicEntityResponse: IJuristicEntityResponse[]): IJuristicEntity[] {
        try {
            const juristicEntity = [] as IJuristicEntity[];
            for (const item of juristicEntityResponse) {
                juristicEntity.push({
                    id: item.id,
                    title: item.name,
                    inn: item.inn,
                    responsiblePersons: item.responsiblePersons,
                    role: item.role,
                });
            }

            return juristicEntity;
        }
        catch (error: any) {
            throw new Error(`Juristic Persons Transformer Error: ${error.message ? error.message : ''}`);
        }
    }
    public toRequest() {}
}
