import { GetterTree } from 'vuex';
import { IRootState } from '@store/store';
import { IWorkListState, IConstruct, IWork } from './interfaces/Interfaces';

export const getters: GetterTree<IWorkListState, IRootState> = {
    workListLoaded: (state) => state.workListLoaded,
    constructsGrouping: (state) => state.constructsGrouping,
    viewingDocument: (state) => state.viewingDocument,
    workList: (state) => state.workList,
    viewingWork: (state) => state.viewingWork,
    pathToViewingWork: (state): Set<IWork> => state.pathToViewingWork,
    actionOnConstruct: (state) => state.actionOnConstruct,
    selectedViewingConstructs(state): IConstruct[] {
        return state.viewingWork?.constructs?.filter((construct) => construct.selected) || [];
    },
    filters: (state) => {
        return state.filters;
    },
    filtersApply: (state) => {
        return state.filtersApply;
    },
    searchResultIdsChain: (state) => {
        return state.searchResultIdsChain;
    },
    addingExecutiveDocumentConstructId: (state) => {
        return state.addingExecutiveDocumentConstructId;
    },
    draggingState: (state) => {
        return state.draggingState;
    },
    docPacks: (state) => {
        const output: any[] = [];
        const added: string[] = [];
        state.workList.forEach(work => {
            output.push(work)
            work.constructs.forEach(construct => {
                if (!added.includes(construct.dockPack.uuid)) {
                    added.push(construct.dockPack.uuid);
                    output.push(construct.dockPack);
                }
            });
        });
        return output;
    }
}
