import axios, { AxiosRequestConfig } from 'axios'

const APP_ENVIRONMENT = process.env.NODE_ENV;
// const SMARTE_AUTHORIZATION_KEY = process.env.SMARTE_AUTHORIZATION_KEY;
// const SMARTE_BASIC_AUTH = process.env.SMARTE_BASIC_AUTH;
const SMARTE_AUTHORIZATION_KEY = 'c3VZb3d6SEtoVjdMQmg2R1V6UVpQOElYSkFWbWNRdmlTU1VP';
const SMARTE_BASIC_AUTH = 'YWRtaW46MDA3';

const client = axios.create({
    baseURL: '/api/',
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
    },
})

client.interceptors.request.use(
    config => {
        putAuthorizationHeaders(config);
        return config;
    },
    reason => Promise.reject(reason),
)

function putAuthorizationHeaders(config: AxiosRequestConfig): void {
    if (config.url?.includes('smart')) {
        putSmarteAuthorizationHeader(config);
        return;
    }
    putBearerAuthorizationHeaders(config);
}

function putBearerAuthorizationHeaders(config: AxiosRequestConfig): void {
    const token = localStorage.getItem('token')
    if (token) config.headers['dacon-token'] = token;
}

function putSmarteAuthorizationHeader(config: AxiosRequestConfig): void {
    if (APP_ENVIRONMENT === 'development') config.headers['Authorization'] = `Basic ${SMARTE_BASIC_AUTH}`
    config.headers['Authorization-Key'] = SMARTE_AUTHORIZATION_KEY;
}

export default client;
