import { MutationTree } from 'vuex';
import {
    IProjectTeamState,
    IMemberInfo,
    IProjectTeamMember,
    IMemberRole,
    IMemberRight,
    IMemberDocStatuses,
} from './interfaces/Interfaces';
import { IUserInfoResponse, IMemberRightResponse } from './interfaces/ApiResponses';
import { ProjectTeamMutations } from './Types';
import { ProjectTeamTransformer } from './transformers/ProjectTeamTransformer';
import { UserRightsTransformer } from '@store/modules/project/transformers/UserRightsTransformer';
import { AboutProjectMutations } from '@store/modules/about-project/Types';
import { IViewMode } from '@/interfaces/IViewMode';

export const mutations: MutationTree<IProjectTeamState> = {
    [ProjectTeamMutations.M_SET_PROJECT_TEAM_LOADED](state, loadedState: boolean) {
        state.projectTeamLoaded = loadedState;
    },
    [ProjectTeamMutations.M_SET_PROJECT_TEAM](state, separatedProjectTeam: Map<IProjectTeamMember, IUserInfoResponse>) {
        const projectTeamTransformer = new ProjectTeamTransformer();
        state.projectTeam = projectTeamTransformer.toDomain(separatedProjectTeam);
    },
    [ProjectTeamMutations.M_SET_MEMBER_TEAM](state, separatedProjectTeam: Map<IProjectTeamMember, IUserInfoResponse>) {
        const projectTeamTransformer = new ProjectTeamTransformer();
        // Замена старой информации об участнике проекта на новую
        for (let [key, value] of Object.entries(state.projectTeam)) {
            state.projectTeam[key] = value.map((member: IProjectTeamMember) => {
                if (member?.uuid === projectTeamTransformer.toDomain(separatedProjectTeam)[key][0]?.uuid) {
                    return projectTeamTransformer.toDomain(separatedProjectTeam)[key][0];
                }
                return member;
            })
        }
    },
    [ProjectTeamMutations.M_SET_MEMBER_RIGHTS](state, rightsResponse: IMemberRightResponse[]) {
        // список прав для селекта и отображения
        const userRightsTransformer = new UserRightsTransformer();
        const rolesExceptions = ['Администратор', 'администратор'];
        state.memberRights = userRightsTransformer.toDomain(rightsResponse).filter(item => !rolesExceptions.includes(item.label));
    },
    [ProjectTeamMutations.M_SET_VIEWED_MEMBER](state, viewedMember: IMemberInfo) {
        state.viewedMember = viewedMember;
    },
    [ProjectTeamMutations.M_ADD_MEMBER_RIGHT](state, right: IMemberRight) {
        state.viewedMember.rights.push(right);
    },
    [ProjectTeamMutations.M_DELETE_MEMBER_RIGHT](state, right: IMemberRight) {
        const newRightsList : any[] = [];
        state.viewedMember.rights.forEach(item => {
            if (item.uuid !== right.uuid) {
                newRightsList.push(item);
            }
        });
        state.viewedMember.rights = newRightsList;
    },
    [ProjectTeamMutations.M_APPLY_ROLE_TO_SELECTED](state, role: IMemberRole) {
        for (const member of state.projectTeam.daconMembers.concat(state.projectTeam.smartcomMembers)) {
            if (member.selected) {
                member.role = role;
                member.selected = false;
            }
        }
    },
    [ProjectTeamMutations.M_APPLY_ROLE_TO_VIEWED_MEMBER](state, role: IMemberRole) {
        state.viewedMember.role = role;
    },
    [ProjectTeamMutations.M_CHANGE_MEMBER_DOC_STATUSES](state, statuses: IMemberDocStatuses[]) {
        state.viewedMember.docStatuses = statuses;
    },
    [AboutProjectMutations.M_SET_FILTERS](state, value) {
        state.filters = value;
    },
    [AboutProjectMutations.M_SET_FILTERS_APPLY](state, value) {
        state.filtersApply = value;
    },
    [ProjectTeamMutations.M_SET_CURRENT_VIEW_MODE](state, viewMode: IViewMode | null) {
        state.currentViewMode = viewMode;
    }
};
