import { IProject, IProjectResponse } from '../Interfaces';
import { PersonTransformer } from './PersonTransformer';
import { ProjectTasksTransformer } from './ProjectTasksTransformer';
import { UsersTransformer } from './UsersTransformer';

export class ProjectTransformer {
    public toDomain(projectResponse: IProjectResponse): IProject {
        try {
            const personTransformer = new PersonTransformer();
            const projectTasksTransformer = new ProjectTasksTransformer();
            const usersTransformer = new UsersTransformer();

            const project = {
                id: projectResponse.appId,
                projectName: projectResponse.name,
                status: projectResponse.status,
                startDate: projectResponse.startDate,
                endDate: projectResponse.endDate,
                title: projectResponse.object,
                createDate: projectResponse.createDate,
                changeDate: projectResponse.changeDate,
                address: projectResponse.objectAddress,
                author: personTransformer.toDomain(projectResponse.author),
                changer: personTransformer.toDomain(projectResponse.changer),
                projectTasks: projectTasksTransformer.toDomain(projectResponse.projectTasks),
                newDocuments: projectResponse.newDocuments,
                newTeam: projectResponse.newTeamMembers,
                newTasks: projectResponse.newTasks,
                description: projectResponse.objectDescription,
                members: usersTransformer.toDomain(projectResponse.users),
            } as IProject;

            return project;
        }
        catch (error: any) {
            throw new Error(`Project Transformer Error: ${error.message ? error.message : ''}`);
        }
    }
    public toRequest() {}
}
