import { Module } from 'vuex';
import { IDocumentsState } from './Interfaces';
import { IRootState } from '../../store';
import { namespace } from 'vuex-class';
import { getters } from './Getters';
import { actions } from './Actions';
import { mutations } from './Mutations';

export const projectDocumentsNamespace = namespace('projectDocumentsNamespace');

const state = {
    DEPENDS_ON_PROJECT_STORE: true,
    projectDocumentsLoaded: false,
    documents: [],
    documentsUnread: [],
    selectedDocuments: [],
    statuses: [],
    categoryList: [],
    packPDList: [],
    viewingDocument: null,
    compositeShow: false,
    comments: [],
    commentsLoaded: false,
    filters: [],
    filtersApply: false,
    draggingState: false,
    viewMode: '',
} as IDocumentsState;

export const storeProjectDocuments = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
} as Module<IDocumentsState, IRootState>
