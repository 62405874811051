export enum ProjectDocumentsActions {
    A_PROJECT_STORE_LISTENER = 'A_PROJECT_STORE_LISTENER',
    A_GET_DOCUMENT_STATUSES = 'A_GET_DOCUMENT_STATUSES',
    A_CHANGE_DOCUMENT_STATUS = 'A_CHANGE_DOCUMENT_STATUS',
    A_GET_CATEGORY_LIST = 'A_GET_CATEGORY_LIST',
    A_CREATE_DOCUMENT = 'A_CREATE_DOCUMENT',
    A_SIGN_DOCUMENT = 'A_SIGN_DOCUMENT',
    A_GET_COMMENTS = 'A_GET_COMMENTS',
    A_SEND_NEW_COMMENT = 'A_SEND_NEW_COMMENT',
    A_CREATE_TASK = 'A_CREATE_TASK',
    A_ADD_COMPOSITE_FOLDER = 'A_ADD_COMPOSITE_FOLDER',
    A_GET_PACK_PD_OPTIONS = 'A_GET_PACK_PD_OPTIONS',
    A_SEND_READ_DOCUMENTS = 'A_SEND_READ_DOCUMENTS',
    A_SET_DOCUMENT_PARENT = 'A_SET_DOCUMENT_PARENT',
    A_REMOVE_DOCUMENT = 'A_REMOVE_DOCUMENT',
    A_REMOVE_FILES = 'A_REMOVE_FILES',
    A_UPLOAD_FILE = 'A_UPLOAD_FILE',
    A_DOCUMENT_RENAME = 'A_DOCUMENT_RENAME',
    A_SET_UNREAD_DOCUMENTS = 'A_SET_UNREAD_DOCUMENTS',
    A_DOWNLOAD_FILES_ZIP = 'A_DOWNLOAD_FILES_ZIP',
    A_SET_DRAGGING_STATE = 'A_SET_DRAGGING_STATE',
    A_SET_VIEW_MODE = 'A_SET_VIEW_MODE',
    A_DOWNLOAD_EXECUTIVE_DOCS = 'A_DOWNLOAD_EXECUTIVE_DOCS',
    A_GET_DOCUMENTS = 'A_GET_DOCUMENTS',
    A_SET_PROJECT_DOCUMENTS = 'A_SET_PROJECT_DOCUMENTS',
    A_DOWNLOAD_DATA = 'A_DOWNLOAD_DATA',
    A_GET_DOC_FILES = 'A_GET_DOC_FILES',
    A_GET_SET_ACTIONS = 'A_GET_SET_ACTIONS',
    A_SET_PACK_PD_ACTIONS = 'A_SET_PACK_PD_ACTIONS',
}

export enum ProjectDocumentsMutations {
    M_SET_PROJECT_DOCUMENTS_LOADED = 'M_SET_PROJECT_DOCUMENTS_LOADED',
    M_SET_PROJECT_DOCUMENTS = 'M_SET_PROJECT_DOCUMENTS',
    M_SET_SELECTED_DOCUMENTS = 'M_SET_SELECTED_DOCUMENTS',
    M_SET_DOCUMENT_STATUSES = 'M_SET_DOCUMENT_STATUSES',
    M_SET_CATEGORY_LIST = 'M_SET_CATEGORY_LIST',
    M_SET_VIEWING_DOCUMENT = 'M_SET_VIEWING_DOCUMENT',
    M_SET_COMPOSITE_SHOW = 'M_SET_COMPOSITE_SHOW',
    M_SET_COMMENTS = 'M_SET_COMMENTS',
    M_SET_COMMENTS_LOADED = 'M_SET_COMMENTS_LOADED',
    M_SET_PACK_PD_OPTIONS = 'M_SET_PACK_PD_OPTIONS',
    M_SET_FILTERS = 'M_SET_FILTERS',
    M_SET_FILTERS_APPLY = 'M_SET_FILTERS_APPLY',
    M_SET_UNREAD_DOCUMENTS = 'M_SET_UNREAD_DOCUMENTS',
    M_SET_DRAGGING_STATE = 'M_SET_DRAGGING_STATE',
    M_SET_VIEW_MODE = 'M_SET_VIEW_MODE',
    M_SET_DOCUMENTS = 'M_SET_DOCUMENTS',
    M_SET_DOCUMENT_FILES = 'M_SET_DOCUMENT_FILES',
    M_SET_DOCUMENT_NAME = 'M_SET_DOCUMENT_NAME',
}
