export enum AboutProjectActions {
    A_PROJECT_STORE_LISTENER = 'A_PROJECT_STORE_LISTENER',
    A_SET_ABOUT_PROJECT_LOADED = 'A_SET_ABOUT_PROJECT_LOADED',
    A_GET_INDIVIDUAL_MEMBERS_ABOUT_PROJECT = 'A_GET_INDIVIDUAL_MEMBERS_ABOUT_PROJECT',
    A_GET_JURISTIC_MEMBERS_ABOUT_PROJECT = 'A_GET_JURISTIC_MEMBERS_ABOUT_PROJECT',
    A_CREATE_MEMBER_JURISTIC = 'A_CREATE_MEMBER_JURISTIC',
    A_CREATE_MEMBER = 'A_CREATE_MEMBER',
    A_EDIT_PROJECT = 'A_EDIT_PROJECT',
    A_EDIT_RESPONSIBLE_PERSON = 'A_EDIT_RESPONSIBLE_PERSON',
    A_EDIT_JURISTIC_PERSON = 'A_EDIT_JURISTIC_PERSON',
    A_CHANGE_PROJECT_STATUS = 'A_CHANGE_PROJECT_STATUS',
    A_CHANGE_PROJECT_TITLE_OR_ADDRESS = 'A_CHANGE_PROJECT_TITLE_OR_ADDRESS',
    A_GET_JURISTIC_ENTITIES = 'A_GET_JURISTIC_ENTITIES',
    A_SET_JURISTIC_ENTITIES = 'A_SET_JURISTIC_ENTITIES',
}

export enum AboutProjectMutations {
    M_SET_ABOUT_PROJECT_LOADED = 'M_SET_ABOUT_PROJECT_LOADED',
    M_SET_INDIVIDUAL_MEMBERS_ABOUT_PROJECT = 'M_SET_INDIVIDUAL_MEMBERS_ABOUT_PROJECT',
    M_SET_JURISTIC_MEMBERS_ABOUT_PROJECT = 'M_SET_JURISTIC_MEMBERS_ABOUT_PROJECT',
    M_SET_FILTERS = 'M_SET_FILTERS',
    M_SET_FILTERS_APPLY = 'M_SET_FILTERS_APPLY',
    M_SET_JURISTIC_ENTITIES = 'M_SET_JURISTIC_ENTITIES',
}
