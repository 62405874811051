import { Module } from 'vuex';
import { actions } from './Actions';
import { mutations } from './Mutations';
import { getters } from './Getters';
import { IRootState } from '@store/store';
import { IWorkListState } from './interfaces/Interfaces';
import { storeWorkListPopups } from './modules/popups';

const state = {
    DEPENDS_ON_PROJECT_STORE: true,
    workListLoaded: false,
    constructsGrouping: 'composite',
    workList: [],
    pathToViewingWork: new Set(),
    viewingWork: {},
    viewingWorkTitle: '',
    constructPacks: {},
    viewingPack: [],
    viewingPackExecutive: '',
    viewingPackTitle: '',
    viewingDocument: {},
    actionOnConstruct: {},
    filters: [],
    filtersApply: false,
    searchResultIdsChain: [],
    addingExecutiveDocumentConstructId: null,
    draggingState: false,
} as any;

export const storeWorkList = {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
    modules: {
        storeWorkListPopups,
    },
} as Module<IWorkListState, IRootState>;
