// const API_URI = process.env.SMARTE_API_URI;

const API_URI = 'https://smartcomm.smrte.ru/api/v1';     // для прода
// const API_URI = 'https://test-smarte.flagstudio.ru/api/v1';   // для тестовых

interface Parameters {
    projectId?: number;
    respondId?: number;
}

export const smarteApi = {
    'smarte/find': (params?: Parameters | undefined): string => `${API_URI}/order`,
    'smarte/orders/all': (params?: Parameters | undefined): string => `${API_URI}/order/${params?.projectId}/orders/all`,
}
