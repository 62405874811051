import { ActionTree } from 'vuex';
import { IRootState } from '@store/store';
import { InputControlActionTypes, InputControlMutationTypes } from '@store/modules/input-control/Types';
import { IInputControl } from '@store/modules/input-control/Interfaces';
import http from '@/http';
import { methods } from '@/ones-api';
import { IErrorsPopup } from "@store/modules/user/Interfaces";

const openBackErrorPopup = (errorText: string = ''): IErrorsPopup => {
    return {
        isOpen: true,
        errorText,
    };
};

export const actions: ActionTree<IInputControl, IRootState> = {
    async [InputControlActionTypes.A_PROJECT_STORE_LISTENER]({ commit, dispatch, rootState }) {
        try {
            // тут будем получать инфу
            commit(InputControlMutationTypes.M_SET_INPUT_CONTROL_LOADED, false);
            await dispatch(InputControlActionTypes.A_GET_INPUT_CONTROL_DOCUMENTS, rootState.storeProject.project.id);
            commit(InputControlMutationTypes.M_SET_INPUT_CONTROL_LOADED, true);
        } catch (error) {
            if (error?.response?.status !== 401) {
                this.dispatch(
                    'storeUser/A_SET_BACK_ERROR',
                    openBackErrorPopup('Ошибка при загрузке данных. Пожалуйста, перезагрузите страницу.')
                );
            }
            return Promise.reject(error);
        }
    },

    async [InputControlActionTypes.A_GET_INPUT_CONTROL_DOCUMENTS]({ commit }, projectId: number) {
        try {
            const method = await methods['input-control/material-reciepts']({ appId: projectId });
            const res = await http.get(method.url, { params: method.params });
            commit(InputControlMutationTypes.M_SET_INPUT_CONTROL_DOCUMENTS, res.data);
        } catch (error) {
            return Promise.reject(error);
        }
    },

    async [InputControlActionTypes.A_GET_LOG_OF_INPUT_CONTROL](_, payload): Promise<any> {
        try {
            const method = await methods['input-control/log-of-input-control'](payload.data);
            await http.get(method.url, { params: method.params, responseType: 'blob' })
                .then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', payload.fileName);
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                });
        } catch (error) {
            this.dispatch(
                'storeUser/A_SET_BACK_ERROR',
                openBackErrorPopup('Не удалось скачать Журнал входного контроля')
            );
            return Promise.reject(error);
        }
    },
};
