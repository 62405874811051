import { GetterTree } from 'vuex';
import { IRootState } from '@store/store';
import {IMemberRight, IProjectTeamState, IMemberInfo} from './interfaces/Interfaces';
import { IViewMode } from '@/interfaces/IViewMode';

export const getters: GetterTree<IProjectTeamState, IRootState> = {
    projectTeamLoaded: (state): boolean => state.projectTeamLoaded,
    projectTeam: (state): IMemberInfo[] => {
        const output: IMemberInfo[] = [];
        const added: string[] = [];
        state.projectTeam.daconMembers.forEach((item) => {
            added.push(item.uuid);
            output.push(item);
        });
        state.projectTeam.smartcomMembers.forEach((item) => {
            if (!added.includes(item.uuid)) {
                added.push(item.uuid);
                output.push(item);
            }
        })
        return output;
    },
    daconMembers: (state, getters): IMemberInfo[] => {
        if (getters.projectNewMembers && getters.projectNewMembers.length) {
            const newMembers = state.projectTeam.daconMembers.filter(member => getters.projectNewMembers.includes(member.uuid));
            return newMembers.concat(state.projectTeam.daconMembers.filter(member => !getters.projectNewMembers.includes(member.uuid)))
        }
        return state.projectTeam.daconMembers;
    },
    smartcomMembers: (state, getters): IMemberInfo[] => {
        if (getters.projectNewMembers && getters.projectNewMembers.length) {
            const newMembers = state.projectTeam.smartcomMembers.filter(member => getters.projectNewMembers.includes(member.uuid));
            return newMembers.concat(state.projectTeam.smartcomMembers.filter(member => !getters.projectNewMembers.includes(member.uuid)))
        }
        return state.projectTeam.smartcomMembers;
    },
    deactivatedMembers: (state, getters, rootState, rootGetters): IMemberInfo[] => {
        const membersWithoutAccess = rootGetters['storeProject/projectData'].members.filter(
            item => item.portalPart === null, item => item.engineerPart === null
        );
        const membersWithoutAccessIds = membersWithoutAccess.map(item => item.uuid);
        const output: IMemberInfo[] = [];
        state.projectTeam.allMembers.forEach((item) => {
            if (membersWithoutAccessIds.includes(item.uuid)) {
                output.push(item);
            }
        });
        return output;
    },
    viewedMember: (state): IMemberInfo => state.viewedMember,

    isAnyMemberSelected(_, getters): boolean {
        return getters.projectTeam.some((member: IMemberInfo) => member.selected);
    },
    currentViewMode: (state): IViewMode | null => state.currentViewMode,
    memberRights: (state): IMemberRight[] => state.memberRights,
    projectNewMembers(_, __, rootState): string[] | null {
        return rootState.storeProject.project.newTeam;
    },
    filters: (state) => {
        return state.filters;
    },
    filtersApply: (state) => {
        return state.filtersApply;
    }
};
