import {
    IConstructDocument,
    IDockPack,
    IKitBrand,
    IDocumentStatus,
} from '../interfaces/Interfaces';
import { IConstructDocumentResponse } from '../interfaces/ApiResponses';
import { IPerson } from '@/interfaces/IPerson';
import { FilesTransformer } from '../../project/transformers/FilesTransformer';

export class ConstructDocumentsTransformer {
    public toDomain(documentsResponse: IConstructDocumentResponse[]): IConstructDocument[] {
        const filesTransformer = new FilesTransformer();
        const constructDocuments = [] as IConstructDocument[];

        for (const document of documentsResponse) {
            const dockPack = {
                uuid: document.packPD?.id ?? '',
                title: document.packPD?.name ?? '',
            } as IDockPack;

            const kitBrand = {
                uuid: document.kitBrand?.id ?? '',
                title: document.kitBrand?.name ?? '',
            } as IKitBrand;

            const status = {
                uuid: document.status?.id ?? '',
                title: document.status?.name ?? '',
            } as IDocumentStatus;

            const author = {
                uuid: document.author?.id ?? '',
                fullName: document.author?.name ?? '',
            } as IPerson;

            const changer = {
                uuid: document.changer?.id ?? '',
                fullName: document.changer?.name ?? '',
            } as IPerson;

            constructDocuments.push({
                uuid: document.id,
                title: document?.fullName || document?.name || '',
                description: document.description,
                category: document.category,
                dockPack,
                kitBrand,
                version: document.version,
                status,
                author,
                changer,
                createDate: document.createDate,
                changeDate: document.changeDate,
                files: filesTransformer.toDomain(document.files),
            });
        }

        return constructDocuments;
    }
    public toRequest() {}
}
