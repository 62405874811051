export enum ProjectRespondsActions {
    A_PROJECT_STORE_LISTENER = 'A_PROJECT_STORE_LISTENER',
    A_GET_PROJECT_ALL_ORDERS = 'A_GET_PROJECT_ALL_ORDERS',
}

export enum ProjectRespondsMutations {
    M_SET_PROJECT_ORDERS = 'M_SET_PROJECT_ORDERS',
    M_SET_PROJECT_RESPONDS = 'M_SET_PROJECT_RESPONDS',
    M_SET_RESPONDS_LOADED = 'M_SET_RESPONDS_LOADED',
}
