import { ILoginData, ILoginDataResponse } from "../Interfaces";

export class LoginTransformer {
    public toDomain(loginResponse: ILoginDataResponse): ILoginData {
        return {
            userUuid: loginResponse.userId,
            accessToken: loginResponse.access_token,
            refreshToken: loginResponse.refresh_token,
        }
    }
    public toRequest() {}
}
