import { MutationTree } from 'vuex'
import {
    IProjectState,
    IProjectResponse,
    IMarkKit,
    IMarkKitResponse,
    ISearchResult,
} from './Interfaces'
import { IDocument } from '@store/modules/project-documents/Interfaces'
import { ProjectMutations } from './Types'
import { ProjectTransformer } from './transformers/ProjectTransformer'
import { MarkKitTransformer } from './transformers/MarkKitTransformer'
import { IMemberRoleResponse, IMemberRoleJuristicResponse } from './Interfaces';
import { MemberRolesTransformer } from './transformers/MemberRolesTransformer';

const projectTransformer = new ProjectTransformer();
const markKitTransformer = new MarkKitTransformer();

export const mutations: MutationTree<IProjectState> = {
    [ProjectMutations.M_SET_PROJECT](state, projectResponse: IProjectResponse) {
        state.project = projectTransformer.toDomain(projectResponse);
    },

    [ProjectMutations.M_TOGGLE_PROJECT_LOADED](state, projectLoadedState: boolean) {
        state.projectLoaded = projectLoadedState;
    },

    [ProjectMutations.M_SET_MART_KIT](state, markKitResponse: IMarkKitResponse[]) {
        state.projectMarkKits = markKitTransformer.toDomain(markKitResponse);
    },

    [ProjectMutations.M_SET_DOCUMENTS](state, document: IDocument) {
        const newState = state.project;
        function recursion(array) {
            const output: IDocument[] = [];
            for (let i = 0; i < array.length; i++) {
                if (array[i].uuid === document.uuid) {
                    array[i] = document;
                }
                output.push(array[i]);
                if (array[i].children && array[i].children.length) {
                    recursion(array[i].children)
                }
            }
            return output;
        }

        newState.documents = recursion(newState.documents);
        state.project = newState;
    },

    [ProjectMutations.M_SET_MEMBER_ROLES](state, payload: IMemberRoleResponse[]) {
        const memberRolesTransformer = new MemberRolesTransformer();
        state.memberRoles = memberRolesTransformer.toDomain(payload);
    },
    [ProjectMutations.M_SET_MEMBER_ROLES_JURISTIC](state, payload: IMemberRoleJuristicResponse[]) {
        const memberRolesTransformer = new MemberRolesTransformer();
        state.memberRolesJuristic = memberRolesTransformer.toDomain(payload);
    },
    [ProjectMutations.M_SET_SEARCH_RESULT](state, payload: ISearchResult) {
        state.searchResult = payload;
    },
    [ProjectMutations.M_SET_NEW_TASKS](state, payload: string[]) {
        state.project.newTasks = payload;
    },
    [ProjectMutations.M_SET_NEW_PROJECT_TEAM](state, payload: string[]) {
        state.project.newTeam = payload;
    },
    [ProjectMutations.M_SET_NEW_DOCUMENTS](state, payload: string[]) {
        state.project.newDocuments = payload;
    },
    [ProjectMutations.M_SET_PROJECT_TITLE](state, title: string) {
        state.project.title = title;
        state.project.projectName = title;
    },
    [ProjectMutations.M_SET_PROJECT_ADDRESS](state, address: string) {
        state.project.address = address;
    },
    [ProjectMutations.M_SET_PROJECT_DESCRIPTION](state, description: string) {
        state.project.description = description;
    },
    [ProjectMutations.M_SET_PROJECT_START_DATE](state, startDate: string) {
        state.project.startDate = startDate;
    },
    [ProjectMutations.M_SET_PROJECT_END_DATE](state, endDate: string) {
        state.project.endDate = endDate;
    },
    [ProjectMutations.M_SET_SEARCHING_PROCESS](state, val: boolean) {
        state.searchingProcess = val;
    },
}
