import { ITask } from "../interfaces/Interfaces";
import { ITaskResponse } from "../interfaces/ApiResponses";
import { PersonTransformer } from "@store/modules/project/transformers/PersonTransformer";
import { FilesTransformer } from "@store/modules/project/transformers/FilesTransformer";
import { TaskSubjectTransformer } from "./TaskSubjectTransformer";

export class TasksTransformer {
    public toDomain(tasksResponse: ITaskResponse[]): ITask[] {
        const personTransformer = new PersonTransformer();
        const filesTransformer = new FilesTransformer();
        const tasksTransformer = new TasksTransformer();
        const taskSubjectTransformer = new TaskSubjectTransformer();

        return tasksResponse.map((task) => ({
            uuid: task.id,
            title: task.name,
            state: task.state,
            author: personTransformer.toDomain(task.author),
            executor: personTransformer.toDomain(task.performer) ?? null,
            files: filesTransformer.toDomain(task.files),
            description: task.description,
            comment: task.comment,
            commentTask: task.commentTask,
            creationDate: task.creationDate,
            deadline: task.deadline,
            subtasks: task.subtasks ? tasksTransformer.toDomain(task.subtasks) : null,
            subject: task.subject ? taskSubjectTransformer.toDomain(task.subject) : null,
            selected: false,
            isImportant: task.isImportant,
        })) as ITask[];
    }
    public toRequest() {}
}
