import { ActionTree } from 'vuex';
import { IRootState } from '@store/store';
import { UserProjectsActions, UserProjectsMutations } from './Types';
import { IUserProjectsState, IStatusResponse } from './Interfaces';
import { AxiosResponse } from 'axios';
import { methods } from '@/ones-api';
import http from '@/http';

export const actions: ActionTree<IUserProjectsState, IRootState> = {
    async [UserProjectsActions.A_GET_USER_PROJECTS]({ commit }) {
        try {
            const apiMethod = methods['project/list']({});
            const { data }: AxiosResponse<IStatusResponse> = await http.get(apiMethod.url, { params: apiMethod.params });
            commit(UserProjectsMutations.M_SET_USER_PROJECTS, data);
        }
        catch (error) {
            return Promise.reject(error);
        }
    },

    async [UserProjectsActions.A_GET_PROJECT_STATUSES]({ commit }) {
        try {
            const apiMethod = methods['project/status-list']({});
            const { data }: AxiosResponse<IStatusResponse> = await http.get(apiMethod.url, { params: apiMethod.params });
            commit(UserProjectsMutations.M_SET_PROJECT_STATUSES, data);
        } catch (error) {
            return Promise.reject(error);
        }
    },

    async [UserProjectsActions.A_GET_ALL_USERS]({ commit }) {
        try {
            const apiMethod = methods['user/all']({});
            const { data }: AxiosResponse<any> = await http.get(apiMethod.url, { params: apiMethod.params });
            commit(UserProjectsMutations.M_SET_ALL_USERS, data);
        } catch (error) {
            return Promise.reject(error);
        }
    },
}
