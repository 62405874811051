import { ISubjectResponse } from "../interfaces/ApiResponses";
import { ISubject } from "../interfaces/Interfaces";

export class TaskSubjectTransformer {
    public toDomain(subjectResponse: ISubjectResponse): ISubject {
        return {
            uuid: subjectResponse.id,
            title: subjectResponse.name,
            systemType: subjectResponse.systemType,
        };
    }
    public toRequest() {}
}
