import { IRespondResponse } from "../interfaces/ApiResponses";
import { IRespond } from "../interfaces/Interfaces";

export class RespondsTransformer {
    public toDomain(respondsResponse: IRespondResponse[]): IRespond[] {
        try {
            const responds = [] as IRespond[];

            for (const respond of respondsResponse) {
                responds.push({
                    count: respond.count,
                    category: respond.order_category,
                    status: respond.order_status,
                    title: respond.order_title,
                    linkToSmartcom: respond.order_uri,
                });
            }

            return responds;
        }
        catch (error: any) {
            throw new Error(`Responds Transformer Error: ${error.message ? error.message : ''}`);
        }
    }
    public toRequest() {}
}
