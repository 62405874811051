import {ActionTree} from 'vuex'
import {IRootState} from '@store/store'
import {
    IProjectTeamState,
    IProjectTeamMember,
    IMemberRight,
    IMemberRole,
    IMemberInfo,
    IMemberDocStatuses
} from './interfaces/Interfaces'
import { IUserInfoResponse, IMemberRightResponse } from './interfaces/ApiResponses'
import {
    IChangeMemberRightsPostData,
    IFindMemberPostData,
    IInviteMemberPostData,
    IChangeMemberDocStatusesPostData
} from './interfaces/ApiRequests'
import { ProjectTeamActions, ProjectTeamMutations } from './Types'
import { AxiosResponse } from 'axios';
import http from '@/http'
import { methods } from '@/ones-api';
import { smarteApi } from '@/smarte-api';
import { FormDataMaker } from '@utility/FormDataMaker';
import { IErrorsPopup } from '@store/modules/user/Interfaces';

const openBackErrorPopup = (errorText: string = ''): IErrorsPopup => {
    return {
        isOpen: true,
        errorText,
    }
};

export const actions: ActionTree<IProjectTeamState, IRootState> = {
    async [ProjectTeamActions.A_PROJECT_STORE_LISTENER]({ commit, dispatch }, projectId) {
        try {
            commit(ProjectTeamMutations.M_SET_PROJECT_TEAM_LOADED, false);
            await Promise.all([
                dispatch(ProjectTeamActions.A_ENRICH_MEMBERS_INFO, projectId),
            ]);

            commit(ProjectTeamMutations.M_SET_PROJECT_TEAM_LOADED, true);
        } catch (error) {
            if (error?.response?.status !== 401) {
                this.dispatch(
                    'storeUser/A_SET_BACK_ERROR',
                    openBackErrorPopup('Ошибка при загрузке данных. Пожалуйста, перезагрузите страницу.')
                );
            }
            return Promise.reject(error);
        }
    },

    async [ProjectTeamActions.A_ENRICH_MEMBERS_INFO]({commit, rootState}, projectId: number) {
        const projectMembers = rootState.storeProject.project.members;
        const membersInfo: Map<IProjectTeamMember, IUserInfoResponse> = new Map();
        if (projectMembers) {
            await Promise.all(projectMembers.map(async (member: IProjectTeamMember) => {
                const apiMethod = methods['user/info']({userId: member.uuid, appId: projectId});
                const userInfo: AxiosResponse<IUserInfoResponse> = await http.get(apiMethod.url, {params: apiMethod.params});
                membersInfo.set(member, userInfo.data);
            }));
        }
        commit(ProjectTeamMutations.M_SET_PROJECT_TEAM, membersInfo);
    },

    async [ProjectTeamActions.A_ENRICH_MEMBER_INFO]({commit, rootState}, payload: { projectId: number, userId: string }) {
        try {
            const projectMember: IProjectTeamMember = rootState.storeProject.project.members
                .filter((member: IProjectTeamMember) => member.uuid === payload.userId)
                .map((member: IProjectTeamMember) => member)[0];
            const memberInfo: Map<IProjectTeamMember, IUserInfoResponse> = new Map();
            const apiMethod = methods['user/info']({userId: payload.userId, appId: payload.projectId});
            const userInfo: AxiosResponse<IUserInfoResponse> = await http.get(apiMethod.url, {params: apiMethod.params});
            memberInfo.set(projectMember, userInfo.data);
            commit(ProjectTeamMutations.M_SET_MEMBER_TEAM, memberInfo);
        } catch (e) {
            return Promise.reject(e);
        }
    },

    async [ProjectTeamActions.A_GET_MEMBER_RIGHTS]({commit}, projectId: number) {
        try {
            const apiMethod = methods['user/rights-list']({appId: projectId});
            const {data}: AxiosResponse<IMemberRightResponse[]> = await http.get(apiMethod.url, {params: apiMethod.params});
            commit(ProjectTeamMutations.M_SET_MEMBER_RIGHTS, data);
        } catch (error) {
            return Promise.reject(error);
        }
    },

    [ProjectTeamActions.A_SET_VIEWED_MEMBER]({commit}, viewedMember: IMemberInfo) {
        commit(ProjectTeamMutations.M_SET_VIEWED_MEMBER, viewedMember);
    },

    async [ProjectTeamActions.A_FIND_MEMBER](_, payload: { params: IFindMemberPostData }) {
        try {
            const smarteApiUrl = smarteApi['smarte/find']();
            await http.post(smarteApiUrl, payload.params);
        } catch (error) {
            return Promise.reject(error);
        }
    },

    async [ProjectTeamActions.A_INVITE_MEMBER]({commit}, payload: { params: IInviteMemberPostData }) {
        try {
            const apiMethod = methods['auth/send-invitation']({...payload.params});
            await http.post(apiMethod.url, apiMethod.params);
        } catch (error) {
            this.dispatch(
                'storeUser/A_SET_BACK_ERROR',
                openBackErrorPopup(`Не удалось отправить приглашение на регистрацию`)
            );
            return Promise.reject(error);
        }
    },

    async [ProjectTeamActions.A_ADD_RIGHT_TO_MEMBER]({commit}, payload: { params: IChangeMemberRightsPostData, right: IMemberRight }) {
        try {
            const apiMethod = methods['user/add-rights']({...payload.params});

            const response: AxiosResponse<any> = await http.post(apiMethod.url, apiMethod.params);
            if (!response.data.error) commit(ProjectTeamMutations.M_ADD_MEMBER_RIGHT, payload.right);
            return response;
        } catch (error) {
            this.dispatch(
                'storeUser/A_SET_BACK_ERROR',
                openBackErrorPopup(`Не удалось добавить право доступа пользователю`)
            );
            return Promise.reject(error);
        }
    },

    async [ProjectTeamActions.A_DELETE_MEMBER_RIGHT]({commit}, payload: { params: IChangeMemberRightsPostData, right: IMemberRight }) {
        try {
            const apiMethod = methods['user/remove-rights']({...payload.params});

            const response: any = await http.post(apiMethod.url, apiMethod.params);
            if (!response.data.error) commit(ProjectTeamMutations.M_DELETE_MEMBER_RIGHT, payload.right);
            return response;
        } catch (error) {
            this.dispatch(
                'storeUser/A_SET_BACK_ERROR',
                openBackErrorPopup(`Не удалось удалить право доступа у пользователя`)
            );
            return Promise.reject(error);
        }
    },

    async [ProjectTeamActions.A_CHANGE_MEMBER_DOC_STATUSES]({commit}, payload: { params: IChangeMemberDocStatusesPostData, statuses: IMemberDocStatuses[] }) {
        try {
            const apiMethod = methods['user/set-doc-statuses']({...payload.params});
            const response: AxiosResponse<any> = await http.post(apiMethod.url, apiMethod.params);
            if (response.status === 200 && !response.data.error) commit(ProjectTeamMutations.M_CHANGE_MEMBER_DOC_STATUSES, payload.statuses);
        } catch (error) {
            this.dispatch(
                'storeUser/A_SET_BACK_ERROR',
                openBackErrorPopup(`Не удалось установить доступные статусы пользователя`)
            );
            return Promise.reject(error);
        }
    },

    async [ProjectTeamActions.A_APPLY_ROLE_TO_MEMBER](_, payload: { role: IMemberRole, projectId: number, member: IMemberInfo, }) {
        try {
            const formData = new FormDataMaker({
                appId: payload.projectId.toString(),
                id: payload.member.individualId,
                entityId: payload.member.entityId,
                roleId: payload.member.role.uuid,
                name: payload.member.fullName,
                newRoleId: payload.role.uuid,
            }).make();
            const apiMethod = methods[`responsible-persons/update`](formData);
            return await http.post(apiMethod.url, apiMethod.params, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
        } catch (error) {
            this.dispatch(
                'storeUser/A_SET_BACK_ERROR',
                openBackErrorPopup(`Не удалось назначить роль`)
            );
            return Promise.reject(error);
        }
    },

    async [ProjectTeamActions.A_APPLY_ROLE_TO_SELECTED](
        {commit, dispatch, rootState},
        payload: { role: IMemberRole, projectId: number },
    ) {
        try {
            const selectedMembers: IMemberInfo[] = Array.from(new Set(
                rootState.storeProjectTeam.projectTeam.daconMembers
                    .concat(rootState.storeProjectTeam.projectTeam.smartcomMembers)
                    .filter(member => member.selected)
            ));
            for (const member of selectedMembers) {
                if (member.selected) {
                    await dispatch(ProjectTeamActions.A_APPLY_ROLE_TO_MEMBER, {...payload, member});
                }
            }
            commit(ProjectTeamMutations.M_APPLY_ROLE_TO_SELECTED, payload.role);
        } catch (error) {
            return Promise.reject(error);
        }
    },

    async [ProjectTeamActions.A_APPLY_ROLE_TO_VIEWED_MEMBER](
        {commit, dispatch, rootState},
        payload: { role: IMemberRole, projectId: number },
    ) {
        try {
            const member = rootState.storeProjectTeam.viewedMember;
            const response: AxiosResponse<any> =
                await dispatch(ProjectTeamActions.A_APPLY_ROLE_TO_MEMBER, {...payload, member});
            if (!response.data.error)
                commit(ProjectTeamMutations.M_APPLY_ROLE_TO_VIEWED_MEMBER, payload.role);
        } catch (error) {
            return Promise.reject(error);
        }
    },

    async [ProjectTeamActions.A_EDIT_PROJECT_MEMBER](
        { commit, rootState },
        payload: { userId: string, fullName: string, email: string, phone: string }
    ) {
        try {
            const selectedEditItem = JSON.parse(JSON.stringify(rootState.storeProjectTeam.viewedMember));

            const apiMethod = methods[`user/set-info`](payload);
            await http.post(apiMethod.url, apiMethod.params);

            for (let key in payload) {
                selectedEditItem[key] = payload[key];
            }
            commit(ProjectTeamMutations.M_SET_VIEWED_MEMBER, selectedEditItem);
        } catch (error) {
            this.dispatch(
                'storeUser/A_SET_BACK_ERROR',
                openBackErrorPopup(`Не удалось изменить информацию пользователя`)
            );
            return Promise.reject(error);
        }
    },

    async [ProjectTeamActions.A_DEACTIVATE_MEMBER](
        {commit},
        payload: { appId: number, userId: string }
    ) {
        try {
            const apiMethod = methods[`user/deactivate`](payload);
            await http.post(apiMethod.url, apiMethod.params);
        } catch (error) {
            this.dispatch(
                'storeUser/A_SET_BACK_ERROR',
                openBackErrorPopup(`Не удалось отключить пользователя`)
            );
            return Promise.reject(error);
        }
    },
}
