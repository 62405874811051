import { ActionTree } from 'vuex';
import { IRootState } from '@store/store';
import { IProjectState, IProjectResponse, IMemberRoleResponse } from './Interfaces';
import { ProjectActions, ProjectMutations } from './Types';
import { AxiosResponse } from 'axios';
import http from '@/http';
import { methods } from '@/ones-api';
import { IErrorsPopup } from '@store/modules/user/Interfaces';

const openBackErrorPopup = (errorText: string = ''): IErrorsPopup => {
    return {
        isOpen: true,
        errorText,
    };
};

export const actions: ActionTree<IProjectState, IRootState> = {
    async [ProjectActions.A_GET_PROJECT]({commit, dispatch}, payload): Promise<void> {
        try {
            const projectId = payload.projectId;
            const apiMethod = methods['project/info']({appId: projectId});
            const methodParams = { ...apiMethod.params, new: true };
            const { data }: AxiosResponse<IProjectResponse> = await http.get(apiMethod.url, { params: methodParams });

            commit(ProjectMutations.M_SET_PROJECT, data);
            if (data.newDocuments && data.newDocuments.length) {
                dispatch('storeProjectDocuments/A_SET_UNREAD_DOCUMENTS', data.newDocuments, { root: true });
            }

            if (payload.reloadDocuments) {
                await dispatch('storeProjectDocuments/A_PROJECT_STORE_LISTENER', projectId, { root: true });
            } else if (payload.reloadProjectTeam) {
                await dispatch('storeProjectTeam/A_PROJECT_STORE_LISTENER', projectId, { root: true });
            } else if (!payload.reloadDocuments) {
                dispatch(ProjectActions.A_NOTIFY_ALL_OTHER_STORES);
            }
        } catch (error) {
            if (error?.response?.status !== 401) {
                this.dispatch(
                    'storeUser/A_SET_BACK_ERROR',
                    openBackErrorPopup('Ошибка при загрузке данных. Пожалуйста, перезагрузите страницу.')
                );
            }
            return Promise.reject(error)
        }
    },

    async [ProjectActions.A_NOTIFY_ALL_OTHER_STORES]({dispatch, rootState, state}) {
        Promise.all(Object.entries(rootState).map((storeKeyValue) => {
            if (!storeKeyValue[1].DEPENDS_ON_PROJECT_STORE) return null;
            return dispatch(`${storeKeyValue[0]}/A_PROJECT_STORE_LISTENER`, state.project.id, { root: true });
        }));
    },

    async [ProjectActions.A_GET_MART_KIT]({commit}): Promise<void> {
        try {
            const apiMethod = methods['project/kit-brand']();
            const { data } = await http.get(apiMethod.url);
            commit(ProjectMutations.M_SET_MART_KIT, data);
        } catch (error) {
            return Promise.reject(error)
        }
    },

    async [ProjectActions.A_GET_MEMBER_ROLES]({commit}, projectId: number) {
        try {
            const apiMethod = methods['responsible-persons/roles']({ appId: projectId });
            const { data }: AxiosResponse<IMemberRoleResponse[]> = await http.get(apiMethod.url, { params: apiMethod.params });
            commit(ProjectMutations.M_SET_MEMBER_ROLES, data);
        } catch (error) {
            return Promise.reject(error);
        }
    },

    async [ProjectActions.A_GET_MEMBER_ROLES_JURISTIC]({commit}, projectId: number) {
        try {
            const apiMethod = methods['kontragent/roles']({ appId: projectId });
            const { data }: AxiosResponse<IMemberRoleResponse[]> = await http.get(apiMethod.url, { params: apiMethod.params });
            commit(ProjectMutations.M_SET_MEMBER_ROLES_JURISTIC, data);
        } catch (error) {
            return Promise.reject(error);
        }
    },

    async [ProjectActions.A_SEARCH_IN_PROJECT]({commit}, payload: { appId: number, query: string }) {
        try {
            commit(ProjectMutations.M_SET_SEARCHING_PROCESS, true);
            const apiMethod = methods['project/search'](payload);
            const { data } = await http.get(apiMethod.url, {
                params: apiMethod.params,
                paramsSerializer: (params) => {
                    let result = '';
                    Object.keys(params).forEach(key => {
                        result += `${key}=${encodeURIComponent(params[key])}&`;
                    });
                    return result.substring(0, result.length - 1);
                }
            });
            return data;
        } catch (error) {
            return Promise.reject(error);
        }
    },

    async [ProjectActions.A_SEND_FEEDBACK]({commit}, payload) {
        try {
            const apiMethod = methods['project/send-feedback'](payload);
            await http.post(apiMethod.url, apiMethod.params);
        } catch (error) {
            this.dispatch(
                'storeUser/A_SET_BACK_ERROR',
                openBackErrorPopup('Не удалось отправить сообщение обратной связи')
            );
            return Promise.reject(error);
        }
    },

    async [ProjectActions.A_SET_SEARCH_RESULT]({commit}, payload) {
        try {
            commit(ProjectMutations.M_SET_SEARCH_RESULT, payload);
        } catch (error) {
            return Promise.reject(error);
        }
    },

    async [ProjectActions.A_READ_OBJECTS]({commit}, payload: {
        appId: number | string,
        ids: string[],
        type: 'document' | 'task' | 'member'
    }) {
        let { appId, ids, type } = payload;
        try {
            const apiMethod = methods['project/read-objects']({ appId, ids });
            await http.post(apiMethod.url, apiMethod.params);
        } catch (error) {
            const isOneObject = ids.length < 2;
            const typeText = {
                'document': `документ${isOneObject ? '' : 'ы'}`,
                'task': `задач${isOneObject ? 'у' : 'и'}`,
                'member': 'участника',
            }
            this.dispatch(
                'storeUser/A_SET_BACK_ERROR',
                openBackErrorPopup(`Не удалось "просмотреть" ${typeText[type]}`)
            );
            return Promise.reject(error);
        }
    },

    [ProjectActions.A_SET_PROJECT_TITLE]({commit}, title: string) {
        commit(ProjectMutations.M_SET_PROJECT_TITLE, title);
    },

    [ProjectActions.A_SET_SEARCHING_PROCESS]({commit}, val: boolean) {
        commit(ProjectMutations.M_SET_SEARCHING_PROCESS, val);
    },
}
