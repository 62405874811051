import { GetterTree } from 'vuex';
import { IUserState } from './Interfaces';
import { IRootState } from '@store/store';

export const getters: GetterTree<IUserState, IRootState> = {
    userData: (state) => {
        return state;
    },
    favorites: (state) => {
        return state.userInfo.favorites;
    },
    isAdminOnCurrentProject: (state, getters) => {
        if (getters.usersOnProject && getters.usersOnProject.length) {
            const userOnCurrentProject = getters.usersOnProject.filter(user => user.uuid === state.userInfo.id);
            if (userOnCurrentProject && userOnCurrentProject[0]) {
                return userOnCurrentProject[0].isAdmin;
            }
        }
        return false;
    },
    usersOnProject(_, __, rootState) {
        const output: any[] = [];
        const added: string[] = [];
        rootState.storeProjectTeam.projectTeam.daconMembers.forEach((item) => {
            added.push(item.uuid);
            output.push(item);
        });
        rootState.storeProjectTeam.projectTeam.smartcomMembers.forEach((item) => {
            if (!added.includes(item.uuid)) {
                added.push(item.uuid);
                output.push(item);
            }
        })
        return output;
    },
    tokenRefreshing(state) {
        return state.tokenRefreshing;
    },
    backError(state) {
        return state.backError;
    },
    userRightOnlyGSN(state) {
        return state.userRightOnlyGSN;
    }
};
