import { IUser, IUserResponse} from "../Interfaces";
import { FavoritesTransformer } from "./FavoritesTransformer";

export class UserTransformer {
    public toDomain(userResponse: IUserResponse): IUser {
        try {
            const favoritesTransformer = new FavoritesTransformer();
            const user = {
                id: userResponse.userId,
                fullName: userResponse.name,
                login: userResponse.login,
                fromSmartcom: userResponse.fromSmartcom,
                email: userResponse.email,
                phone: userResponse.phone,
                favorites: favoritesTransformer.toDomain(userResponse.favorites),
                portalPart: userResponse.portalPart,
                engineerPart: userResponse.engineerPart,
            } as IUser;

            return user;
        }
        catch (error: any) {
            throw new Error(`User Transformer Error: ${error.message ? error.message : ''}`);
        }
    }
    public toRequest() {}
}
