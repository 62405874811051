export enum UserProjectsActions {
    A_GET_USER_PROJECTS = 'A_GET_USER_PROJECTS',
    A_GET_PROJECT_STATUSES = 'A_GET_PROJECT_STATUSES',
    A_GET_ALL_USERS = 'A_GET_ALL_USERS',
}

export enum UserProjectsMutations {
    M_SET_USER_PROJECTS = 'M_SET_USER_PROJECTS',
    M_SET_USER_PROJECTS_GETTING = 'M_SET_USER_PROJECTS_GETTING',
    M_SET_PROJECT_STATUSES = 'M_SET_PROJECT_STATUSES',
    M_SET_ALL_USERS = 'M_SET_ALL_USERS',
    M_CHANGE_VIEW = 'M_CHANGE_VIEW',
    M_SET_FILTERS = 'M_SET_FILTERS',
    M_CHANGE_VIEW_MODE = 'M_CHANGE_VIEW_MODE',
    M_SET_FILTERS_APPLY = 'M_SET_FILTERS_APPLY',
}
