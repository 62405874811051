import { GetterTree, Module } from "vuex";
import { getters } from "./Getters";
import { IProjectTeamPopupsState } from "./Interfaces";
import { IRootState } from "@store/store";
import { PopupImpl } from '@store/Popup';

const state = {
    addMemberPopupToggler: false,
    rolesPopupToggler: false,
} as IProjectTeamPopupsState;

class ProjectTeamPopupsStore extends PopupImpl<IProjectTeamPopupsState> {
    namespaced: boolean;
    state: IProjectTeamPopupsState;
    getters: GetterTree<IProjectTeamPopupsState, IRootState>;

    constructor() {
        super();
        this.namespaced = true;
        this.state = state;
        this.getters = getters;
    }
}

export const storeProjectTeamPopups = new ProjectTeamPopupsStore() as Module<IProjectTeamPopupsState, IRootState>;
