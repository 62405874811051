import { IInputControlDocumentQuality, IInputControlDocumentQualityResponse } from '@store/modules/input-control/Interfaces';

export class DocumentsQualityTransformer {
    public toDomain(inputControlDocumentsQualityResponse: IInputControlDocumentQualityResponse[]): IInputControlDocumentQuality[] {
        const documents = [] as IInputControlDocumentQuality[];
        for (const document of inputControlDocumentsQualityResponse) {
            if (document && document.id && document.name) {
                documents.push({
                    uuid: document.id,
                    title: document?.fullName || document?.name || '',
                    files: document.files,
                });
            }
        }

        return documents;
    }
}
