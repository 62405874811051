import { GetterTree } from 'vuex';
import { IAboutProject, IJuristicEntity } from './Interfaces';
import { IRootState } from '../../store';

export const getters: GetterTree<IAboutProject, IRootState> = {
    aboutProjectLoaded: (state): boolean => {
        return state.aboutProjectLoaded;
    },
    individualMembers: (state) => {
        return state.individualMembers;
    },
    juristicMembers: (state) => {
        return state.juristicMembers;
    },
    filters: (state) => {
        return state.filters;
    },
    filtersApply: (state) => {
        return state.filtersApply;
    },
    juristicEntities: (state): IJuristicEntity[] => {
        return state.juristicEntities;
    },
};
