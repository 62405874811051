import { IInputControlDocument, IInputControlDocumentResponse } from '@store/modules/input-control/Interfaces';
import { DocumentsQualityTransformer } from './DocumentsQualityTransformer';

export class DocumentsTransformer {
    public toDomain(inputControlDocumentsResponse: IInputControlDocumentResponse[]): IInputControlDocument[] {
        const documentsQualityTransformer = new DocumentsQualityTransformer();

        const documents = [] as IInputControlDocument[];
        for (const document of inputControlDocumentsResponse) {
            documents.push({
                uuid: document.id,
                title: document.name,
                unit: document.unit,
                count: document.count,
                applied: document.applied,
                documentsQuality: documentsQualityTransformer.toDomain(document.documents),
            });
        }

        return documents;
    }
}
