// const API_URI = process.env.DACON_API_URI;
// const API_URI = 'https://en.dacon.pro/app/dacon_api/hs/api';     // для прода (старый)
const API_URI = 'https://en.dacon.pro/app/dacon_api/hs/dacon-api';     // для прода (новый)
// const API_URI = 'https://dacon.kaminsoft.ru/int/dacon/hs/api';   // для тестовых (старый)
// const API_URI = 'https://dacon.kaminsoft.ru/a/sm_hs/hs/dacon-api';  // для тестовых (новый)

interface Method {
    params: Parameters | undefined;
    url: string;
}

interface Parameters {
    [key: string]: any;
}

export const methods = {
    'project/info': (params?: Parameters | undefined): Method => ({
        params,
        url: `${API_URI}/project/info`,
    }),
    'project/status-list': (params?: Parameters | undefined): Method => ({
        params,
        url: `${API_URI}/project/status-list`,
    }),
    'user/info': (params?: Parameters | undefined): Method => ({
        params,
        url: `${API_URI}/user/info`,
    }),
    'user/add-rights': (params?: Parameters | undefined): Method => ({
        params,
        url: `${API_URI}/user/add-rights`,
    }),
    'user/remove-rights': (params?: Parameters | undefined): Method => ({
        params,
        url: `${API_URI}/user/remove-rights`,
    }),
    'user/rights-list': (params?: Parameters | undefined): Method => ({
        params,
        url: `${API_URI}/user/rights-list`,
    }),
    'user/all': (params?: Parameters | undefined): Method => ({
        params,
        url: `${API_URI}/user/all`,
    }),
    'works/list': (params?: Parameters | undefined): Method => ({
        params,
        url: `${API_URI}/works/list`,
    }),
    'works/set-fact': (params?: Parameters | undefined): Method => ({
        params,
        url: `${API_URI}/works/set-fact`,
    }),
    'works/set-parent': (params?: Parameters | undefined): Method => ({
        params,
        url: `${API_URI}/works/set-parent`,
    }),
    'works/new-structure': (params?: Parameters | undefined): Method => ({
        params,
        url: `${API_URI}/works/new-structure`,
    }),
    'responsible-persons/roles': (params?: Parameters | undefined): Method => ({
        params,
        url: `${API_URI}/responsible-persons/roles`,
    }),
    'responsible-persons/individual-list': (params?: Parameters | undefined): Method => ({
        params,
        url: `${API_URI}/responsible-persons/individual-list`,
    }),
    'kontragent/list': (params?: Parameters | undefined): Method => ({
        params,
        url: `${API_URI}/kontragent/list`,
    }),
    'tasks/list': (params?: Parameters | undefined): Method => ({
        params,
        url: `${API_URI}/tasks/list`,
    }),
    'tasks/new-task': (params?: Parameters | undefined): Method => ({
        params,
        url: `${API_URI}/tasks/new-task`,
    }),
    'tasks/perform-task': (params?: Parameters | undefined): Method => ({
        params,
        url: `${API_URI}/tasks/perform-task`,
    }),
    'tasks/return-task': (params?: Parameters | undefined): Method => ({
        params,
        url: `${API_URI}/tasks/return-task`,
    }),
    'tasks/accept-task': (params?: Parameters | undefined): Method => ({
        params,
        url: `${API_URI}/tasks/check-task`,
    }),
    'tasks/take-task': (params?: Parameters | undefined): Method => ({
        params,
        url: `${API_URI}/tasks/take-task`,
    }),
    'kontragent/roles': (params: Parameters): Method => ({
        params,
        url: `${API_URI}/kontragent/roles`,
    }),
    'kontragent/new': (params: Parameters): Method => ({
        params,
        url: `${API_URI}/kontragent/new`,
    }),
    'responsible-persons/new-individual': (params: Parameters): Method => ({
        params,
        url: `${API_URI}/responsible-persons/new-individual`,
    }),
    'document/new-folder': (params: Parameters): Method => ({
        params,
        url: `${API_URI}/document/new-folder`,
    }),
    'document/packPD-list': (params: Parameters): Method => ({
        params,
        url: `${API_URI}/document/packPD-list`,
    }),
    'input-control/material-reciepts': (params: Parameters): Method => ({
        params,
        url: `${API_URI}/input-control/material-reciepts`,
    }),
    'input-control/log-of-input-control': (params?: Parameters): Method => ({
        params,
        url: `${API_URI}/input-control/log-of-input-control`,
    }),
    'document/read': (params: Parameters): Method => ({
        params,
        url: `${API_URI}/document/read`,
    }),
    'document/set-parent': (params?: Parameters): Method => ({
        params,
        url: `${API_URI}/document/set-parent`,
    }),
    'document/remove': (params?: Parameters): Method => ({
        params,
        url: `${API_URI}/document/remove`,
    }),
    'project/list': (params?: Parameters): Method => ({
        params,
        url: `${API_URI}/project/list`,
    }),
    'document/status-list': (params?: Parameters): Method => ({
        params,
        url: `${API_URI}/document/status-list`,
    }),
    'document/category-list': (params?: Parameters): Method => ({
        params,
        url: `${API_URI}/document/category-list`,
    }),
    'document/comment-list': (params?: Parameters): Method => ({
        params,
        url: `${API_URI}/document/comment-list`,
    }),
    'document/set-status': (params?: Parameters): Method => ({
        params,
        url: `${API_URI}/document/set-status`,
    }),
    'document/new': (params?: Parameters): Method => ({
        params,
        url: `${API_URI}/document/new`,
    }),
    'document/new-comment': (params?: Parameters): Method => ({
        params,
        url: `${API_URI}/document/new-comment`,
    }),
    'auth/login': (params?: Parameters): Method => ({
        params,
        url: `${API_URI}/auth/login`,
    }),
    'project/kit-brand': (params?: Parameters): Method => ({
        params,
        url: `${API_URI}/project/kit-brand`,
    }),
    'auth/refresh': (params?: Parameters): Method => ({
        params,
        url: `${API_URI}/auth/refresh`,
    }),
    'favorite/add': (params?: Parameters): Method => ({
        params,
        url: `${API_URI}/favorite/add`,
    }),
    'favorite/remove': (params?: Parameters): Method => ({
        params,
        url: `${API_URI}/favorite/remove`,
    }),
    'project/set-info': (params?: Parameters): Method => ({
        params,
        url: `${API_URI}/project/set-info`,
    }),
    'responsible-persons/update': (params?: Parameters): Method => ({
        params,
        url: `${API_URI}/responsible-persons/update`,
    }),
    'works/remove-structure': (params?: Parameters): Method => ({
        params,
        url: `${API_URI}/works/remove-structure`,
    }),
    'auth/complete-registration': (params?: Parameters): Method => ({
        params,
        url: `${API_URI}/auth/complete-registration`,
    }),
    'auth/complete-registration-info': (params?: Parameters): Method => ({
        params,
        url: `${API_URI}/auth/complete-registration-info`,
    }),
    'auth/send-invitation': (params?: Parameters): Method => ({
        params,
        url: `${API_URI}/auth/send-invitation`,
    }),
    'user/set-doc-statuses': (params?: Parameters): Method => ({
        params,
        url: `${API_URI}/user/set-doc-statuses`,
    }),
    'responsible-persons/new': (params: Parameters): Method => ({
        params,
        url: `${API_URI}/responsible-persons/new`,
    }),
    'files/set-sign': (params: Parameters): Method => ({
        params,
        url: `${API_URI}/files/set-sign`,
    }),
    'tasks/delete-task': (params: Parameters): Method => ({
        params,
        url: `${API_URI}/tasks/delete-task`,
    }),
    'tasks/check-task': (params): Method => ({
        params,
        url: `${API_URI}/tasks/check-task`,
    }),
    'tasks/edit-task': (params): Method => ({
        params,
        url: `${API_URI}/tasks/edit-task`,
    }),
    'kontragent/update-entity': (params): Method => ({
        params,
        url: `${API_URI}/kontragent/update-entity`,
    }),
    'files/remove': (params): Method => ({
        params,
        url: `${API_URI}/files/remove`,
    }),
    'files/upload': (params): Method => ({
        params,
        url: `${API_URI}/files/upload`,
    }),
    'document/rename': (params): Method => ({
        params,
        url: `${API_URI}/document/rename`,
    }),
    'user/set-info': (params): Method => ({
        params,
        url: `${API_URI}/user/set-info`,
    }),
    'user/deactivate': (params): Method => ({
        params,
        url: `${API_URI}/user/deactivate`,
    }),
    'project/search': (params): Method => ({
        params,
        url: `${API_URI}/project/search`,
    }),
    'project/send-feedback': (params): Method => ({
        params,
        url: `${API_URI}/project/send-feedback`,
    }),
    'project/read-objects': (params): Method => ({
        params,
        url: `${API_URI}/project/read-objects`,
    }),
    'files/download-zip': (params): Method => ({
        params,
        url: `${API_URI}/files/download-zip`,
    }),
    'files/download-pack-exec-doc': (params): Method => ({
        params,
        url: `${API_URI}/files/download-pack-exec-doc`,
    }),
    'auth/change-password': (params): Method => ({
        params,
        url: `${API_URI}/auth/change-password`,
    }),
    'user/email-confirm': (params): Method => ({
        params,
        url: `${API_URI}/user/email-confirm`,
    }),
    'auth/send-reset-password-mail': (params): Method => ({
        params,
        url: `${API_URI}/auth/send-reset-password-mail`,
    }),
    'auth/reset-password': (params): Method => ({
        params,
        url: `${API_URI}/auth/reset-password`,
    }),
    'project/documents': (params): Method => ({
        params,
        url: `${API_URI}/project/documents`,
    }),
    'project/entities': (params): Method => ({
        params,
        url: `${API_URI}/project/entities`,
    }),
    'files/list': (params): Method => ({
        params,
        url: `${API_URI}/files/list`,
    }),
    'document/packED-actions': (params): Method => ({
        params,
        url: `${API_URI}/document/packED-actions`,
    }),
    'document/packED-action': (params): Method => ({
        params,
        url: `${API_URI}/document/packED-action`,
    }),
}
