import { IDocumentStatus, IDocumentStatusResponse } from '../../project-documents/Interfaces';

export class StatusTransformer {
    public toDomain(statusResponse: IDocumentStatusResponse): IDocumentStatus {
        try {
            return {
                id: statusResponse?.id,
                title: statusResponse?.name,
            };
        }
        catch (error: any) {
            throw new Error(`Status Transformer Error: ${error.message ? error.message : ''}`);
        }
    }
    public toRequest() {}
}
