import { namespace } from 'vuex-class';
import { Module } from 'vuex';
import { getters } from './Getters';
import { mutations } from './Mutation';
import { ISidebarState } from './Interface';
import { IRootState } from '../../store';

export const sidebarNamespace = namespace('storeSidebar');

class Sidebar implements ISidebarState {
    public sidebarShow = false;
}

export const storeSidebar: any = {
    namespaced: true,
    state: () => new Sidebar(),
    getters,
    mutations,
} as Module<ISidebarState, IRootState>;
