import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store/store';

import { parse } from 'date-fns';
import { format, utcToZonedTime } from 'date-fns-tz';

import '@/scss/index.scss';

Vue.config.productionTip = false;
Vue.config.devtools = true;

Vue.filter('date', (date: string, formatString: string = 'dd.MM.yyyy') => {
  const dateFns = parse(date, 'yyyy-MM-dd HH:mm:ss xxx', new Date());
  const timeZone = 'UTC';

  return format(utcToZonedTime(dateFns, timeZone), formatString);
});

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');

declare module 'vue/types/vue' {
  interface Vue {}
}
