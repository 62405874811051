export const enum ProjectActions {
  A_GET_PROJECT = 'A_GET_PROJECT',
  A_NOTIFY_ALL_OTHER_STORES = 'A_NOTIFY_ALL_OTHER_STORES',
  A_GET_MART_KIT = 'A_GET_MART_KIT',
  A_GET_MEMBER_ROLES = 'A_GET_MEMBER_ROLES',
  A_GET_MEMBER_ROLES_JURISTIC = 'A_GET_MEMBER_ROLES_JURISTIC',
  A_SEARCH_IN_PROJECT = 'A_SEARCH_IN_PROJECT',
  A_SEND_FEEDBACK = 'A_SEND_FEEDBACK',
  A_SET_SEARCH_RESULT = 'A_SET_SEARCH_RESULT',
  A_READ_OBJECTS = 'A_READ_OBJECTS',
  A_SET_PROJECT_TITLE = 'A_SET_PROJECT_TITLE',
  A_SET_SEARCHING_PROCESS = 'A_SET_SEARCHING_PROCESS',
}

export const enum ProjectMutations {
  M_SET_PROJECT = 'M_SET_PROJECT',
  M_TOGGLE_PROJECT_LOADED = 'M_TOGGLE_PROJECT_LOADED',
  M_SET_MART_KIT = 'M_SET_MART_KIT',
  M_SET_DOCUMENTS = 'M_SET_DOCUMENTS',
  M_SET_MEMBER_ROLES = 'M_SET_MEMBER_ROLES',
  M_SET_MEMBER_ROLES_JURISTIC = 'M_SET_MEMBER_ROLES_JURISTIC',
  M_SET_SEARCH_RESULT = 'M_SET_SEARCH_RESULT',
  M_SET_NEW_TASKS = 'M_SET_NEW_TASKS',
  M_SET_NEW_PROJECT_TEAM = 'M_SET_NEW_PROJECT_TEAM',
  M_SET_NEW_DOCUMENTS = 'M_SET_NEW_DOCUMENTS',
  M_SET_PROJECT_TITLE = 'M_SET_PROJECT_TITLE',
  M_SET_SEARCHING_PROCESS = 'M_SET_SEARCHING_PROCESS',
  M_SET_PROJECT_ADDRESS = 'M_SET_PROJECT_ADDRESS',
  M_SET_PROJECT_DESCRIPTION = 'M_SET_PROJECT_DESCRIPTION',
  M_SET_PROJECT_START_DATE = 'M_SET_PROJECT_START_DATE',
  M_SET_PROJECT_END_DATE = 'M_SET_PROJECT_END_DATE',
}
