import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@store/store';

Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'home',
            meta: {
                requiresAuth: true,
            },
        },{
            path: '/auth',
            name: 'auth',
            component: () => import('./pages/the-auth/TheAuth.vue'),
        },{
            path: '/forgot-password',
            name: 'forgot-password',
            component: () => import('./pages/the-auth/ForgotPassword.vue'),
        },{
            path: '/reset-password',
            name: 'reset-password',
            component: () => import('./pages/the-auth/SetNewPassword.vue'),
        },{
            path: '/registration',
            name: 'registration',
            component: () => import('./pages/the-auth/TheRegistration.vue'),
        },{
            path: '/projects/:view?',
            name: 'projects',
            meta: {
                requiresAuth: true,
            },
            component: () => import('./pages/the-user-projects/UserProjects.vue'),
        },{
            path: '/project/:projectId',
            name: 'project',
            meta: {
                requiresAuth: true,
            },
            component: () => import('./pages/the-project/TheProject.vue'),
            children: [
                {
                    path: 'project-team',
                    name: 'project-team',
                    component: () => import('./pages/the-project-team/ProjectTeam.vue'),
                    meta: {
                        title: 'Команда проекта',
                        requiresAuth: true,
                    },
                },{
                    path: 'project-team/:memberUuid',
                    name: 'team-member',
                    component: () => import('./pages/the-team-member/TeamMember.vue'),
                    meta: {
                        title: 'Команда проекта',
                        requiresAuth: true,
                    },
                },{
                    path: 'documents',
                    name: 'project-documents',
                    component: () => import('./pages/the-documents/TheDocuments.vue'),
                    meta: {
                        title: 'Документы проекта',
                        requiresAuth: true,
                    },
                },{
                    path: 'work-list',
                    name: 'work-list',
                    component: () => import('./pages/the-work-list/WorkList.vue'),
                    meta: {
                        title: 'Список работ',
                        requiresAuth: true,
                    },
                },{
                    path: 'project-tasks',
                    name: 'project-tasks',
                    component: () => import('./pages/the-project-tasks/ProjectTasks.vue'),
                    meta: {
                        title: 'Задачи',
                        requiresAuth: true,
                    },
                },{
                    path: 'input-control',
                    name: 'input-control',
                    component: () => import('./pages/input-control/InputControl.vue'),
                    meta: {
                        title: 'Входной контроль',
                        requiresAuth: true,
                    },
                },{
                    path: 'about',
                    name: 'about-project',
                    component: () => import('./pages/about-project/AboutProject.vue'),
                    meta: {
                        title: 'О проекте',
                        requiresAuth: true,
                    },
                },{
                    path: 'responds',
                    name: 'responds',
                    component: () => import('./pages/the-project-responds/ProjectResponds.vue'),
                    meta: {
                        title: 'Команда проекта',
                        requiresAuth: true,
                    },
                },
            ],
        },{
            path: '/email-confirm',
            name: 'emailConfirm',
            meta: {
                requiresAuth: true,
            },
            component: () => import('./pages/email-confirm/EmailConfirm.vue'),
        },
    ],
})

const documentMainTitle = 'Dacon.pro';
router.beforeEach((to, from, next) => {
    localStorage.setItem('routFrom', from.name || '');
    if ( to && to.name !== 'auth' && to.meta && to.meta.requiresAuth ) {
        if (!localStorage.getItem('authUserId')) {
            next({ name: 'auth' })
        } else {
            next()
        }
    } else {
        next()
    }
    if (to && to.name && to.name === 'home') {
        if (!localStorage.getItem('authUserId')) {
            next({ name: 'auth' })
        } else {
            next({ name: 'projects' })
        }
    }
    if (to && to.name && from && from.name
        && !((from.name === 'project-team' && to.name === 'team-member')
            || from.name === 'team-member' && to.name === 'project-team')) {
        store.commit('storeProjectTeam/M_SET_FILTERS_APPLY', false);
        store.commit('storeProjectTeam/M_SET_FILTERS', []);
    }
    if (to.meta !== undefined && to.meta.title) {
        document.title = `${documentMainTitle} - ${to.meta.title}`;
    }
    else document.title = documentMainTitle;

    next();
});

export default router;
