import { IPerson, IPersonResponse } from "@/interfaces/IPerson";

export class PersonTransformer {
    public toDomain(personResponse: IPersonResponse): IPerson {
        try {
            return {
                uuid: personResponse?.id,
                fullName: personResponse?.name,
            };
        }
        catch (error: any) {
            throw new Error(`Person Transformer Error: ${error.message ? error.message : ''}`);
        }
    }
    public toRequest() {}
}
