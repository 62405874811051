import { IFavorite, IFavoriteResponse } from "../Interfaces";

export class FavoritesTransformer {
    public toDomain(favoritesResponse: IFavoriteResponse[]): IFavorite[] {
        try {
            const favorites = [] as IFavorite[];
            for (const favorite of favoritesResponse) {
                favorites.push({
                    id: favorite.id,
                    title: favorite.title,
                    url: favorite.link,
                });
            }
            return favorites;
        }
        catch (error: any) {
            throw new Error(`Favorite Transformer Error: ${error.message ? error.message : ''}`);
        }
    }
    public toRequest() {}
}
