export class FormDataMaker {
    data: Object;

    constructor(data: Object) {
        this.data = data
    }

    make(): FormData {
        const formData = new FormData();
        for (const [key, value] of Object.entries(this.data)) {
            formData.append(key, value);
        }

        return formData;
    }
}
