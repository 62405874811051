import { MutationTree } from 'vuex';
import { UserProjectsMutations } from './Types';
import {
    IUserProjectsState,
    IUserProjectResponse,
    IStatusResponse,
    IAllUsersResponse,
    IUserProjectsView,
} from './Interfaces';
import { UserProjectsTransformer } from './transformers/UserProjectsTransformer';
import { ProjectStatusesTransformer } from './transformers/ProjectStatusesTransformer';
import { AllUsersTransformer } from './transformers/AllUsersTransformer';

export const mutations: MutationTree<IUserProjectsState> = {
    [UserProjectsMutations.M_SET_USER_PROJECTS](state: IUserProjectsState, projectsResponse: IUserProjectResponse[]) {
        const userProjectsTransformer = new UserProjectsTransformer();
        state.projects = userProjectsTransformer.toDomain(projectsResponse);
    },

    [UserProjectsMutations.M_SET_PROJECT_STATUSES](state: IUserProjectsState, statusesResponse: IStatusResponse[]) {
        const projectStatusesTransformer = new ProjectStatusesTransformer();
        state.projectStatuses = projectStatusesTransformer.toDomain(statusesResponse);
    },

    [UserProjectsMutations.M_SET_ALL_USERS](state: IUserProjectsState, usersResponse: IAllUsersResponse) {
        const allUsersTransformer = new AllUsersTransformer();
        state.allUsers = allUsersTransformer.toDomain(usersResponse);
    },

    [UserProjectsMutations.M_CHANGE_VIEW](state: IUserProjectsState, filter: IUserProjectsView) {
        state.currentView = filter.value;
    },

    [UserProjectsMutations.M_CHANGE_VIEW_MODE](state: IUserProjectsState, viewMode: string) {
        state.currentViewMode = viewMode;
    },

    [UserProjectsMutations.M_SET_FILTERS](state, val: any[]) {
        state.filters = val;
    },

    [UserProjectsMutations.M_SET_FILTERS_APPLY](state, val: boolean) {
        state.filtersApply = val;
    },
    [UserProjectsMutations.M_SET_USER_PROJECTS_GETTING](state, val: boolean) {
        state.userProjectsGetting = val;
    },
}
