import { IPackED, IPackEDResponse } from '../Interfaces';

export class PackEDTransformer {
    public toDomain(packEDsResponse: IPackEDResponse[]): IPackED[] {
        try {
            const packEDs = [] as IPackED[];
            for (const item of packEDsResponse) {
                packEDs.push({
                    uuid: item.id,
                    title: item.name,
                    documentsIds: item.documents,
                    agreementProcess: item.pdd,
                    pddTask: item.pddTask,
                });
            }
            return packEDs;
        }
        catch (error: any) {
            throw new Error(`Status Transformer Error: ${error.message ? error.message : ''}`);
        }
    }
    public toRequest() {}
}
