import { GetterTree } from 'vuex';
import { IDocumentsState, IDocument } from './Interfaces';
import { IRootState } from '../../store';

export const getters: GetterTree<IDocumentsState, IRootState> = {
    documents: (state) => {
        const output: IDocument[] = [];

        function recursion(array) {
            for (const item of array) {
                output.push(item);
                if (item.children && item.children.length) {
                    recursion(item.children);
                }
            }
            return output;
        }
        return recursion(state.documents);
    },
    documentsWithoutRecursion: (state) => {
        return state.documents;
    },
    unreadDocuments: (state) => {
        return state.documentsUnread;
    },
    selectedDocuments: (state) => {
        return state.selectedDocuments;
    },
    statuses: (state) => {
        return state.statuses;
    },
    categoryList: (state) => {
        return state.categoryList;
    },
    compositeShow: (state) => {
        return state.compositeShow;
    },
    comments: (state) => {
        return state.comments;
    },
    commentsLoaded: (state) => {
        return state.commentsLoaded;
    },
    packPDList: (state) => {
        return state.packPDList;
    },
    agreementProcesses: (state) => {
        let agreementProcess: any[] = [];
        state.packPDList.forEach((item) => {
            const filteredAgreementProcess = item.packED.filter(packEDItem => packEDItem.agreementProcess);
            agreementProcess = agreementProcess.concat(filteredAgreementProcess);
        });
        return agreementProcess.map(doc => doc.uuid);
    },
    agreementProcessesPackPDLevel: (state) => {
        const agreementProcess: any[] = [];
        state.packPDList.forEach((item) => {
            if (item.packED.filter(packEDItem => packEDItem.agreementProcess).length) {
                agreementProcess.push(item);
            }
        });
        return agreementProcess.map(doc => doc.uuid);
    },
    filters: (state) => {
        return state.filters;
    },
    filtersApply: (state) => {
        return state.filtersApply;
    },
    categoriesForSelect(state, getters) {
        const addedTypes: string[] = [];
        getters.documents.forEach((item) => {
            let key = 'category';
            if (item.type && (item.type === 'Исполнительная документация' || item.type === 'исполнительная документация')) {
                key = 'type';
            }
            if (addedTypes.indexOf(item[key]) === -1
                && !item.isFolder
                && item[key].length
                && item[key] !== 'Исполнительная документация')
            {
                addedTypes.push(item[key]);
            }
        })
        return addedTypes;
    },
    allReadDocuments: (state, getters) => {
        const allReadDocuments: IDocument[] = [];

        const documents = getters.documents.filter(
            doc => doc.author?.uuid,
            doc => doc.status,
        );

        documents.forEach((item) => {
            if (getters.unreadDocuments.indexOf(item.uuid) === -1) {
                allReadDocuments.push(item);
            }
        });

        return allReadDocuments;
    },
    allReadProjectDocuments: (state, getters) => {
        const allReadDocuments = getters.allReadDocuments;
        let output: any[] = [];
        getters.categoriesForSelect.forEach((category) => {
            output = output.concat(allReadDocuments.filter(
                doc => doc.category === category || doc.type === category,
                doc => !doc.isFolder,
            ));
        });
        return output;
    },
    allReadDocumentsIncludeExecutive: (state, getters) => {
        const allReadDocuments = getters.allReadDocuments;
        let output: any[] = [];
        const categories = getters.categoriesForSelect.concat(['Исполнительная документация']);
        categories.forEach((category) => {
            output = output.concat(allReadDocuments.filter(
                doc => doc.category === category || doc.type === category,
                doc => !doc.isFolder,
            ));
        });
        return output;
    },
    draggingState: (state) => {
        return state.draggingState;
    },
    viewMode: (state) => {
        return state.viewMode;
    },
};
