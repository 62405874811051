import { ActionTree } from 'vuex'
import store, { IRootState } from '@store/store'
import { IAboutProject, IJuristicEntityResponse } from './Interfaces';
import { AboutProjectActions, AboutProjectMutations } from './Types';
import { methods } from '@/ones-api';
import { AxiosResponse } from 'axios';
import { IProjectResponse } from '@store/modules/project/Interfaces';
import http from '@/http';
import { IErrorsPopup } from "@store/modules/user/Interfaces";

const openBackErrorPopup = (errorText: string = ''): IErrorsPopup => {
    return {
        isOpen: true,
        errorText,
    };
};

export const actions: ActionTree<IAboutProject, IRootState> = {
    async [AboutProjectActions.A_PROJECT_STORE_LISTENER]({commit}) {
        commit(AboutProjectMutations.M_SET_ABOUT_PROJECT_LOADED, false);
        // тут будем получать инфу
        commit(AboutProjectMutations.M_SET_ABOUT_PROJECT_LOADED, true);
    },

    [AboutProjectActions.A_SET_ABOUT_PROJECT_LOADED]({commit}, loaded: boolean): void {
        commit(AboutProjectMutations.M_SET_ABOUT_PROJECT_LOADED, loaded);
    },

    [AboutProjectActions.A_SET_JURISTIC_ENTITIES]({commit}, entities: IJuristicEntityResponse[]): void {
        commit(AboutProjectMutations.M_SET_JURISTIC_ENTITIES, entities);
    },

    async [AboutProjectActions.A_GET_JURISTIC_ENTITIES]({commit}, projectId: string | number): Promise<any> {
        try {
            const method = methods['project/entities']({ appId: projectId });
            const { data }: AxiosResponse<IProjectResponse> = await http.get(method.url, { params: method.params });
            return data;
        }
        catch (error) {
            return Promise.reject(error)
        }
    },

    async [AboutProjectActions.A_GET_INDIVIDUAL_MEMBERS_ABOUT_PROJECT]({commit}, projectId) {
        try {
            const projectInfoMethod = methods['responsible-persons/individual-list']({ appId: projectId });
            const { data }: AxiosResponse<IProjectResponse> = await http.get(projectInfoMethod.url, { params: projectInfoMethod.params });
            commit(AboutProjectMutations.M_SET_INDIVIDUAL_MEMBERS_ABOUT_PROJECT, data);
        }
        catch (error) {
            return Promise.reject(error)
        }
    },

    async [AboutProjectActions.A_GET_JURISTIC_MEMBERS_ABOUT_PROJECT]({commit}, projectId) {
        try {
            const projectInfoMethod = methods['kontragent/list']({ appId: projectId });
            const { data }: AxiosResponse<IProjectResponse> = await http.get(projectInfoMethod.url, { params: projectInfoMethod.params });
            commit(AboutProjectMutations.M_SET_JURISTIC_MEMBERS_ABOUT_PROJECT, data);
        }
        catch (error) {
            return Promise.reject(error)
        }
    },

    async [AboutProjectActions.A_CREATE_MEMBER_JURISTIC](
        {commit},
        payload: { appId: string, fullName: string, name: string, inn: string, roleId: string }
    ) {
        try {
            const apiMethod = methods['kontragent/new']({ ...payload });
            await http.post(apiMethod.url, apiMethod.params);
        } catch (error) {
            this.dispatch(
                'storeUser/A_SET_BACK_ERROR',
                openBackErrorPopup('Не удалось добавить новое юридическое лицо')
            );
            return Promise.reject(error);
        }
    },

    async [AboutProjectActions.A_CREATE_MEMBER]({commit}, payload) {
        try {
            const apiMethod = methods['responsible-persons/new'](payload);
            await http.post(apiMethod.url, apiMethod.params);
        } catch (error) {
            this.dispatch(
                'storeUser/A_SET_BACK_ERROR',
                openBackErrorPopup('Не удалось добавить новое физическое лицо')
            );
            return Promise.reject(error);
        }
    },

    async [AboutProjectActions.A_EDIT_PROJECT]({state, commit}, payload: {
        name: string,
        objectAddress: string,
        objectDescription: string,
        startDate: string,
        endDate: string,
    }): Promise<any> {
        try {
            let { name, objectAddress, objectDescription, startDate, endDate } = payload;
            const apiMethod = methods['project/set-info'](payload);
            await http.post(apiMethod.url, apiMethod.params);

            store.commit('storeProject/M_SET_PROJECT_TITLE', name);
            store.commit('storeProject/M_SET_PROJECT_ADDRESS', objectAddress);
            store.commit('storeProject/M_SET_PROJECT_DESCRIPTION', objectDescription);
            store.commit('storeProject/M_SET_PROJECT_START_DATE', startDate);
            store.commit('storeProject/M_SET_PROJECT_END_DATE', endDate);
        } catch (error) {
            this.dispatch(
                'storeUser/A_SET_BACK_ERROR',
                openBackErrorPopup('Не удалось изменить информацию о проекте')
            );
            return Promise.reject(error);
        }
    },

    async [AboutProjectActions.A_EDIT_RESPONSIBLE_PERSON]({commit}, payload) {
        try {
            const apiMethod = methods['responsible-persons/update'](payload);
            await http.post(apiMethod.url, apiMethod.params);
        } catch (error) {
            this.dispatch(
                'storeUser/A_SET_BACK_ERROR',
                openBackErrorPopup('Не удалось изменить данные физического лица')
            );
            return Promise.reject(error);
        }
    },

    async [AboutProjectActions.A_EDIT_JURISTIC_PERSON]({commit}, payload) {
        try {
            const apiMethod = methods['kontragent/update-entity'](payload);
            await http.post(apiMethod.url, apiMethod.params);
        } catch (error) {
            this.dispatch(
                'storeUser/A_SET_BACK_ERROR',
                openBackErrorPopup('Не удалось изменить данные юридического лица')
            );
            return Promise.reject(error);
        }
    },

    async [AboutProjectActions.A_CHANGE_PROJECT_STATUS]({commit, rootState}, payload) {
        try {
            for (const project of rootState.storeUserProjects.projects) {
                if (project.id === payload.id) {
                    const apiMethod = methods['project/set-info']({
                        appId: project.id,
                        name: project.title,
                        objectAddress: project.address,
                        objectDescription: project.description,
                        startDate: project.startDate,
                        endDate: project.endDate,
                        statusId: payload.status.id,
                    });
                    await http.post(apiMethod.url, apiMethod.params);
                    project.status = payload.status.title;
                    break;
                }
            }
        } catch (error) {
            this.dispatch(
                'storeUser/A_SET_BACK_ERROR',
                openBackErrorPopup('Не удалось изменить статус проекта')
            );
            return Promise.reject(error);
        }
    },

    async [AboutProjectActions.A_CHANGE_PROJECT_TITLE_OR_ADDRESS]({commit, rootState}, payload) {
        try {
            const project = rootState.storeProject.project;
            if (project.id === payload.id) {
                let title = project.title;
                let address = project.address;
                if (payload.title) {
                    title = payload.title;
                    project.title = payload.title;
                    this.dispatch('storeProject/A_SET_PROJECT_TITLE', payload.title);
                }
                if (payload.address) {
                    address = payload.address;
                    project.address = payload.address;
                }
                const apiMethod = methods['project/set-info']({
                    appId: project.id,
                    name: title,
                    objectAddress: address,
                    objectDescription: project.description,
                    startDate: project.startDate,
                    endDate: project.endDate,
                    statusId: payload.projectStatusId,
                });
                await http.post(apiMethod.url, apiMethod.params);
            }
        } catch (error) {
            return Promise.reject(error);
        }
    },
};
