import { IMemberRight } from '@store/modules/project-team/interfaces/Interfaces';
import { IMemberRightResponse } from '@store/modules/project-team/interfaces/ApiResponses';

export class UserRightsTransformer {
    public toDomain(rightsResponse: IMemberRightResponse[]): IMemberRight[] {
        const userRights = [] as IMemberRight[];

        for (const right of rightsResponse) {
            userRights.push({
                uuid: right.id,
                label: right.name,
                value: right.name,
            });
        }

        return userRights;
    }
    public toRequest() {}
}
