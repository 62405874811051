import { ActionTree, MutationTree } from "vuex";
import { IRootState } from "./store";

interface Popup<PopupConcreteState> {
    actions: ActionTree<PopupConcreteState, IRootState>;
    mutations: MutationTree<PopupConcreteState>;
}

export const enum PopupActions {
    A_TOGGLE_POPUP = 'A_TOGGLE_POPUP',
}

const enum PopupMutations {
    M_TOGGLE_POPUP = 'M_TOGGLE_POPUP',
}

export class PopupImpl<PopupConcreteState> implements Popup<PopupConcreteState> {
    actions: ActionTree<PopupConcreteState, IRootState>;
    mutations: MutationTree<PopupConcreteState>;

    constructor() {
        this.actions = {
            [PopupActions.A_TOGGLE_POPUP]: ({ commit }, payload) => {
                commit('M_TOGGLE_POPUP', payload);
            },
        };

        this.mutations = {
            [PopupMutations.M_TOGGLE_POPUP](state, payload) {
                state[payload.popupToggler] = payload.state;
            },
        };
    }
}
