import { IUserProjectsResponse, IUserApp } from '../Interfaces';
import { UserRightsInAppTransformer } from './UserRightsInAppTransformer';

export class UserAppsTransformer {
    public toDomain(userAppsResponse: IUserProjectsResponse[]): IUserApp[] {
        try {
            const userRightsInAppTransformer = new UserRightsInAppTransformer();
            const userApps = [] as IUserApp[];

            for (const app of userAppsResponse) {
                userApps.push({
                    id: app.appId,
                    title: app.name,
                    roles: userRightsInAppTransformer.toDomain(app.rights),
                    selected: false,
                });
            }

            return userApps;
        }
        catch (error: any) {
            throw new Error(`User Apps Transformer Error: ${error.message ? error.message : ''}`);
        }
    }
    public toRequest() {}
}
