import { MutationTree } from 'vuex';
import { IRespondResponse } from './interfaces/ApiResponses';
import { IProjectRespondsState } from './interfaces/Interfaces';
import { RespondsTransformer } from './transformers/RespondsTransformer';
import { ProjectRespondsMutations } from './Types';

export const mutations: MutationTree<IProjectRespondsState> = {
    [ProjectRespondsMutations.M_SET_PROJECT_ORDERS](state, orders: number[]) {
        state.projectOrders = orders;
    },

    [ProjectRespondsMutations.M_SET_PROJECT_RESPONDS](state, respondsResponse: IRespondResponse[]) {
        const respondsTransformer = new RespondsTransformer();
        state.projectResponds = respondsTransformer.toDomain(respondsResponse);
    },

    [ProjectRespondsMutations.M_SET_RESPONDS_LOADED](state) {
        state.respondsLoaded = true;
    }
}
