import { Module } from "vuex";
import { actions } from "./Actions";
import { mutations } from "./Mutations";
import { getters } from "./Getters";
import { IRootState } from "@store/store";
import { IProjectRespondsState } from "./interfaces/Interfaces";

const state = {
    DEPENDS_ON_PROJECT_STORE: true,
    respondsLoaded: false,
    projectOrders: [],
    projectResponds: [],
} as IProjectRespondsState;

export const storeProjectResponds = {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
} as Module<IProjectRespondsState, IRootState>
