import { ActionTree } from 'vuex';
import { IRootState } from '@store/store'
import { IAuth, ILoginData, IUserState, IErrorsPopup } from './Interfaces';
import { UserActionTypes, UserMutationTypes } from './Types';
import http from '@/http';
import { LoginTransformer } from './transformers/LoginTransformer';
import { methods } from '@/ones-api';

const openBackErrorPopup = (errorText: string = ''): IErrorsPopup => {
    return {
        isOpen: true,
        errorText,
    };
};

export const actions: ActionTree<IUserState, IRootState> = {
    async [UserActionTypes.A_LOGIN]({commit, dispatch, rootState}, payload: { auth: IAuth | null, appId: any }): Promise<any> {
        try {
            if (payload.auth) {
                const apiMethod = methods['auth/login']({
                    email: payload.auth.login,
                    password: payload.auth.password,
                });
                const response = await http.post(apiMethod.url, apiMethod.params);
                const loginTransformer = new LoginTransformer();
                const loginData = loginTransformer.toDomain(response.data);
                dispatch(UserActionTypes.A_SET_LOGIN_DATA_TO_LOCAL_STORAGE, loginData);
                commit(UserMutationTypes.M_USER_TOKEN, loginData.accessToken);
                await this.dispatch('storeUser/A_GET_USER', {
                    userId: response.data.userId,
                    appId: rootState.storeProject.project.id
                }, { root: true });
            } else {
                if (localStorage.getItem('authUserId')) {
                    await this.dispatch('storeUser/A_GET_USER', payload.appId ? {
                        userId: localStorage.getItem('authUserId'),
                        appId: payload.appId
                    } : {
                        userId: localStorage.getItem('authUserId'),
                    }, { root: true });
                }
            }
        } catch (error) {
            return Promise.reject(error);
        }
    },

    [UserActionTypes.A_SET_LOGIN_DATA_TO_LOCAL_STORAGE](_, loginData: ILoginData) {
        localStorage.setItem('token', loginData.accessToken);
        localStorage.setItem('refreshToken', loginData.refreshToken);
        localStorage.setItem('authUserId', loginData.userUuid);
    },

    async [UserActionTypes.A_REFRESH_TOKEN]({commit}): Promise<any> {
        try {
            const apiMethod = methods['auth/refresh']({
                refresh_token: localStorage.getItem('refreshToken'),
            });
            const response = await http.post(apiMethod.url, apiMethod.params);
            localStorage.setItem('token', response.data.accessToken || response.data.access_token);
        } catch (error) {
            return Promise.reject(error)
        }
    },

    async [UserActionTypes.A_LOGOUT]({commit}): Promise<any> {
        localStorage.removeItem('token');
        localStorage.removeItem('authUserId');
        commit(UserMutationTypes.M_LOGOUT);
        commit(UserMutationTypes.M_SET_USER_RIGHT_ONLY_GSN, false);
    },

    async [UserActionTypes.A_GET_USER]({commit, dispatch}, payload): Promise<any> {
        try {
            const method = methods['user/info'](payload);
            const { data } = await http.get(method.url, {params: method.params});
            commit(UserMutationTypes.M_LOGIN, data);
        } catch (error) {
            if (payload.userId !== null && error?.response?.status !== 401) {
                dispatch(
                    'A_SET_BACK_ERROR',
                    openBackErrorPopup('Ошибка при загрузке данных. Пожалуйста, перезагрузите страницу.')
                );
            }
            return Promise.reject(error)
        }
    },

    async [UserActionTypes.A_AUTH_USER]({commit, rootState}, isAuthUser): Promise<any> {
        try {
            commit(UserMutationTypes.M_AUTH_USER, isAuthUser)
        } catch (error) {
            return Promise.reject(error)
        }
    },

    async [UserActionTypes.A_USER_TOKEN]({commit, rootState}, token): Promise<any> {
        try {
            commit(UserMutationTypes.M_USER_TOKEN, token)
        } catch (error) {
            return Promise.reject(error)
        }
    },

    async [UserActionTypes.A_SET_FAVORITE]({ dispatch }, favorite): Promise<any> {
        try {
            const apiMethod = methods['favorite/add'](favorite);
            await http.post(apiMethod.url, apiMethod.params);
        } catch (error) {
            dispatch(
                'A_SET_BACK_ERROR',
                openBackErrorPopup('Не удалось добавить избранное')
            );
            return Promise.reject(error)
        }
    },

    async [UserActionTypes.A_REMOVE_FAVORITE]({ dispatch }, favorite): Promise<any> {
        try {
            const apiMethod = methods['favorite/remove'](favorite);
            await http.post(apiMethod.url, apiMethod.params);
        } catch (error) {
            dispatch(
                'A_SET_BACK_ERROR',
                openBackErrorPopup('Не удалось удалить избранное')
            );
            return Promise.reject(error);
        }
    },

    async [UserActionTypes.A_GET_ACCESS_USER_STATUSES]({state, commit}, projectId: number): Promise<void> {
        try {
            const {data} = await http.get(`projects/${projectId}/document_status/${state.userInfo.id}`);
            commit(UserMutationTypes.M_CHANGE_ACCESS_USER_STATUSES, data);
        } catch (error) {
            return Promise.reject(error);
        }
    },

    async [UserActionTypes.A_COMPLETE_REGISTRATION]({ state, commit, dispatch }, payload): Promise<void> {
        try {
            const apiMethod = methods['auth/complete-registration'](payload);
            await http.post(apiMethod.url, apiMethod.params);
        } catch (error) {
            dispatch(
                'A_SET_BACK_ERROR',
                openBackErrorPopup('Не удалось завершить регистрацию')
            );
            return Promise.reject(error)
        }
    },

    async [UserActionTypes.A_REGISTRATION_INFO]({state, commit}, payload): Promise<void> {
        try {
            const apiMethod = methods['auth/complete-registration-info'](payload);
            const {data} = await http.get(apiMethod.url, {params: payload});
            return data;
        } catch (error) {
            return Promise.reject(error);
        }
    },

    [UserActionTypes.A_SET_BACK_ERROR]({ commit }, payload: IErrorsPopup): void {
        if (!payload.errorText) {
            payload.errorText = `
                Ошибка при выполнении запроса.
                Повторите попытку позже или опишите проблему нашим специалистам с помощью кнопки "Помощь".
            `;
        }
        commit('M_SET_BACK_ERROR', payload);
    },

    [UserActionTypes.A_SET_USER_RIGHT_ONLY_GSN]({commit, rootState}, userId: string) {
        const userObj = rootState.storeProject.project.members.filter((item) => item.uuid === userId)[0];
        if (userObj.rights.length === 1 && userObj.rights[0].label === 'Инспектор ГСН') {
            commit(UserMutationTypes.M_SET_USER_RIGHT_ONLY_GSN, true);
        } else {
            commit(UserMutationTypes.M_SET_USER_RIGHT_ONLY_GSN, false);
        }
    },

    async [UserActionTypes.A_EMAIL_CONFIRM]({ state, dispatch }, payload): Promise<void> {
        try {
            const apiMethod = methods['user/email-confirm'](payload);
            await http.post(apiMethod.url, apiMethod.params);
        } catch (error) {
            dispatch(
                'A_SET_BACK_ERROR',
                openBackErrorPopup('Не удалось подтвердить смену почты')
            );
            return Promise.reject(error);
        }
    },

    async [UserActionTypes.A_FORGOT_PASSWORD]({ state, commit, dispatch }, payload): Promise<void> {
        try {
            const apiMethod = methods['auth/send-reset-password-mail'](payload);
            await http.post(apiMethod.url, apiMethod.params);
        } catch (error) {
            dispatch(
                'A_SET_BACK_ERROR',
                openBackErrorPopup('Не удалось отправить письмо для сброса пароля')
            );
            return Promise.reject(error);
        }
    },

    async [UserActionTypes.A_CHANGE_PASSWORD]({ state, dispatch }, payload): Promise<void> {
        try {
            const apiMethod = methods['auth/change-password'](payload);
            await http.post(apiMethod.url, apiMethod.params);
        } catch (error) {
            dispatch(
                'A_SET_BACK_ERROR',
                openBackErrorPopup('Не удалось изменить пароль пользователя')
            );
            return Promise.reject(error);
        }
    },

    async [UserActionTypes.A_RESET_PASSWORD]({ state, commit, dispatch }, payload): Promise<void> {
        try {
            const apiMethod = methods['auth/reset-password'](payload);
            await http.post(apiMethod.url, apiMethod.params);
        } catch (error) {
            dispatch(
                'A_SET_BACK_ERROR',
                openBackErrorPopup('Во время смены пароля произошла ошибка')
            );
            return Promise.reject(error);
        }
    },

    [UserActionTypes.A_ERROR_HANDLER]({dispatch}, data: { dontTryRefresh?: boolean, text?: string, error?: any, setBackError?: boolean }) {
        if (data.error?.response?.status === 401) {
            dispatch('A_REFRESH_TOKEN');
            dispatch(
                'A_SET_BACK_ERROR',
                openBackErrorPopup('Ошибка при обработке данных. Пожалуйста, повторите попытку.')
            );
        } else if (data.setBackError) {
            dispatch(
                'A_SET_BACK_ERROR',
                openBackErrorPopup(data.error?.response?.data?.message || data.text)
            );
        }
    },
}
