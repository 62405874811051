import { Module } from 'vuex';
import { IAboutProject } from './Interfaces';
import { IRootState } from '../../store';
import { getters } from './Getters'
import { actions } from './Actions';
import { mutations } from './Mutations';

const state = {
    DEPENDS_ON_PROJECT_STORE: true,
    aboutProjectLoaded: false,
    individualMembers: [],
    juristicMembers: [],
    filters: [],
    filtersApply: false,
    juristicEntities: [],
} as IAboutProject;

export const storeAboutProject = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
} as Module<IAboutProject, IRootState>
