import { Module } from 'vuex';
import { IInputControl } from '@store/modules/input-control/Interfaces';
import { IRootState } from '@store/store';
import { getters } from './Getters';
import { actions } from './Actions';
import { mutations } from './Mutations';

const state = {
    DEPENDS_ON_PROJECT_STORE: true,
    inputControlLoaded: false,
    inputControlDocuments: [],
};

export const storeInputControl = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
} as Module<IInputControl, IRootState>
