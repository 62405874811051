import {ActionTree} from 'vuex';
import {IRootState} from '@store/store';
import {IDocument, IDocumentsState, IFile} from './Interfaces';
import {ProjectDocumentsActions, ProjectDocumentsMutations} from './Types';
import http from '@/http';
import {methods} from '@/ones-api';
import {FormDataMaker} from '@utility/FormDataMaker';
import {IErrorsPopup} from '@store/modules/user/Interfaces';

const openBackErrorPopup = (errorText: string = ''): IErrorsPopup => {
    return {
        isOpen: true,
        errorText,
    }
};
import {FilesTransformer} from '@store/modules/project/transformers/FilesTransformer';

const filesTransformer = new FilesTransformer();

export const actions: ActionTree<IDocumentsState, IRootState> = {
    async [ProjectDocumentsActions.A_PROJECT_STORE_LISTENER]({commit, dispatch}) {
    },
    async [ProjectDocumentsActions.A_SET_PROJECT_DOCUMENTS]({commit}, docs) {
        commit(ProjectDocumentsMutations.M_SET_PROJECT_DOCUMENTS, docs);
    },
    async [ProjectDocumentsActions.A_GET_DOCUMENTS]({commit}, projectId) {
        try {
            const method = methods['project/documents']({appId: projectId});
            const {data} = await http.get(method.url, {params: method.params});
            return data;
        } catch (error) {
            if (error?.response?.status !== 401) {
                this.dispatch(
                    'storeUser/A_ERROR_HANDLER',
                    {text: 'Ошибка при загрузке данных. Пожалуйста, перезагрузите страницу.', error, setBackError: true},
                );
            }
            return Promise.reject(error)
        }
    },

    async [ProjectDocumentsActions.A_GET_DOC_FILES]({commit}, payload) {
        try {
            const method = methods['files/list'](payload);
            const {data} = await http.get(method.url, {params: method.params});
            return filesTransformer.toDomain(data);
        } catch (error) {
            this.dispatch(
                'storeUser/A_ERROR_HANDLER',
                {text: '', error, setBackError: true},
            );
            return Promise.reject(error)
        }
    },

    async [ProjectDocumentsActions.A_GET_DOCUMENT_STATUSES]({commit}, projectId) {
        try {
            const method = methods['document/status-list']({appId: projectId});
            const response = await http.get(method.url, {params: method.params});
            if (response.data && response.data.length) {
                commit(ProjectDocumentsMutations.M_SET_DOCUMENT_STATUSES, response.data);
            }
        } catch (error) {
            return Promise.reject(error)
        }
    },

    async [ProjectDocumentsActions.A_CHANGE_DOCUMENT_STATUS]({commit}, payload) {
        try {
            const apiMethod = methods['document/set-status']({
                appId: payload.projectId,
                documentId: payload.documentId,
                statusId: payload.statusId,
            });
            await http.post(apiMethod.url, apiMethod.params);
        } catch (error) {
            this.dispatch(
                'storeUser/A_ERROR_HANDLER',
                {text: `Не удалось изменить статус документ${payload.documentId.length > 1 ? 'ов' : 'a'}`, error, setBackError: true},
            );
            return Promise.reject(error)
        }
    },

    async [ProjectDocumentsActions.A_GET_CATEGORY_LIST]({commit}, projectId) {
        try {
            const method = methods['document/category-list']({appId: projectId});
            const {data} = await http.get(method.url, {params: method.params});
            commit(ProjectDocumentsMutations.M_SET_CATEGORY_LIST, data);
        } catch (error) {
            return Promise.reject(error)
        }
    },

    async [ProjectDocumentsActions.A_CREATE_DOCUMENT]({commit}, payload) {
        try {
            const apiMethod = methods['document/new'](payload);
            await http.post(apiMethod.url, apiMethod.params);
        } catch (error) {
            this.dispatch(
                'storeUser/A_ERROR_HANDLER',
                {
                    text: error?.response?.status === 400 ? 'У Вас не достаточно прав на создание этого документа' : 'Не удалось создать документ',
                    error,
                    setBackError: true
                },
            );
            return Promise.reject(error)
        }
    },

    async [ProjectDocumentsActions.A_SIGN_DOCUMENT]({commit}, payload) {
        try {
            const method = methods['files/set-sign'](payload);
            await http.post(method.url, method.params);
        } catch (error) {
            this.dispatch(
                'storeUser/A_ERROR_HANDLER',
                {text: 'Не удалось добавить подпись к файлу', error, setBackError: true},
            );
            return Promise.reject(error)
        }
    },

    async [ProjectDocumentsActions.A_GET_COMMENTS]({commit}, payload) {
        try {
            const method = methods['document/comment-list']({
                appId: payload.projectId || payload.appId,
                documentId: payload.documentId
            });
            const {data} = await http.get(method.url, {params: method.params});

            commit(ProjectDocumentsMutations.M_SET_COMMENTS, data);
            commit(ProjectDocumentsMutations.M_SET_COMMENTS_LOADED, true);
        } catch (error) {
            return Promise.reject(error)
        }
    },

    async [ProjectDocumentsActions.A_SEND_NEW_COMMENT]({commit, dispatch}, payload): Promise<any> {
        try {
            const apiMethod = methods['document/new-comment'](payload);
            await http.post(apiMethod.url, apiMethod.params);
            dispatch(ProjectDocumentsActions.A_GET_COMMENTS, payload);
        } catch (error) {
            this.dispatch(
                'storeUser/A_ERROR_HANDLER',
                {text: 'Не удалось отправить комментарий', error, setBackError: true},
            );
            return Promise.reject(error)
        }
    },

    async [ProjectDocumentsActions.A_CREATE_TASK]({commit}, payload
    ) {
        try {
            const apiMethod = methods['tasks/new-task'](payload);
            await http.post(apiMethod.url, apiMethod.params);
        } catch (error) {
            this.dispatch(
                'storeUser/A_ERROR_HANDLER',
                {text: 'Не удалось создать задачу', error, setBackError: true},
            );
            return Promise.reject(error);
        }
    },

    async [ProjectDocumentsActions.A_ADD_COMPOSITE_FOLDER](_, payload) {
        try {
            const apiMethod = methods['document/new-folder']({...payload});
            await http.post(apiMethod.url, apiMethod.params);
        } catch (error) {
            this.dispatch(
                'storeUser/A_ERROR_HANDLER',
                {text: 'Не удалось создать новую группу', error, setBackError: true},
            );
            return Promise.reject(error);
        }
    },

    async [ProjectDocumentsActions.A_GET_PACK_PD_OPTIONS]({commit}, projectId) {
        try {
            const method = methods['document/packPD-list']({appId: projectId});
            const response = await http.get(method.url, {params: method.params});
            if (response && response.data) {
                commit(ProjectDocumentsMutations.M_SET_PACK_PD_OPTIONS, response.data);
            }
        } catch (error) {
            return Promise.reject(error);
        }
    },

    async [ProjectDocumentsActions.A_SEND_READ_DOCUMENTS]({commit}, payload) {
        try {
            const apiMethod = methods['document/read']({...payload});
            await http.post(apiMethod.url, apiMethod.params);
        } catch (error) {
            this.dispatch(
                'storeUser/A_ERROR_HANDLER',
                {text: `Не удалось "прочитать" документ${payload.ids.length > 1 ? 'ы' : ''}`, error, setBackError: true},
            );
            return Promise.reject(error);
        }
    },

    async [ProjectDocumentsActions.A_SET_DOCUMENT_PARENT](_, payload) {
        try {
            const apiMethod = methods['document/set-parent']({...payload});
            await http.post(apiMethod.url, apiMethod.params);
        } catch (error) {
            this.dispatch(
                'storeUser/A_ERROR_HANDLER',
                {text: `Не удалось переместить документ${payload.documentId.length > 1 ? 'ы' : ''}`, error, setBackError: true},
            );
            return Promise.reject(error);
        }
    },

    async [ProjectDocumentsActions.A_REMOVE_DOCUMENT]({commit}, payload) {
        try {
            const apiMethod = methods['document/remove']({...payload});
            await http.post(apiMethod.url, apiMethod.params);
        } catch (error) {
            this.dispatch(
                'storeUser/A_ERROR_HANDLER',
                {text: 'Не удалось удалить документ(ы)', error, setBackError: true},
            );
            return Promise.reject(error);
        }
    },

    async [ProjectDocumentsActions.A_REMOVE_FILES]({commit, getters}, payload: {
        data: {
            appId: string,
            id: string[],
        },
        idDoc: string,
    }): Promise<any> {
        try {
            const apiMethod = methods['files/remove']({...payload.data});
            await http.post(apiMethod.url, apiMethod.params);

            let files: IFile[] = [];
            getters.documents.forEach((document: IDocument) => {
                if (document.uuid === payload.idDoc) {
                    files = document.files.filter(file => !payload.data.id.includes(file.uuid));
                }
            });
            commit('M_SET_DOCUMENT_FILES', {idDoc: payload.idDoc, files});
        } catch (error) {
            this.dispatch(
                'storeUser/A_ERROR_HANDLER',
                {text: `Не удалось удалить файл${payload.data.id.length > 1 ? 'ы' : ''}`, error, setBackError: true},
            );
            return Promise.reject(error);
        }
    },

    async [ProjectDocumentsActions.A_UPLOAD_FILE]({commit}, payload) {
        try {
            const apiMethod = methods['files/upload']({...payload});
            const formData = new FormDataMaker({...payload}).make();
            await http.post(apiMethod.url, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data; boundary=-----boundary---0',
                },
            });
        } catch (error) {
            this.dispatch(
                'storeUser/A_ERROR_HANDLER',
                {text: 'Не удалось добавить файл', error, setBackError: true},
            );
            return Promise.reject(error);
        }
    },

    async [ProjectDocumentsActions.A_DOCUMENT_RENAME]({commit, getters}, payload) {
        try {
            const apiMethod = methods['document/rename']({...payload});
            await http.post(apiMethod.url, apiMethod.params);

            commit(ProjectDocumentsMutations.M_SET_DOCUMENT_NAME, {id: payload.id, name: payload.name});
        } catch (error) {
            this.dispatch(
                'storeUser/A_ERROR_HANDLER',
                {text: 'Не удалось переименовать документ', error, setBackError: true},
            );
            return Promise.reject(error);
        }
    },

    async [ProjectDocumentsActions.A_SET_UNREAD_DOCUMENTS]({commit}, payload) {
        try {
            commit(ProjectDocumentsMutations.M_SET_UNREAD_DOCUMENTS, payload);
        } catch (error) {
            return Promise.reject(error);
        }
    },

    async [ProjectDocumentsActions.A_DOWNLOAD_FILES_ZIP]({dispatch}, payload) {
        try {
            await dispatch(ProjectDocumentsActions.A_DOWNLOAD_DATA, {
                api: 'files/download-zip',
                data: payload.data,
                name: payload.nameArchive,
            });
        } catch (error) {
            return Promise.reject(error);
        }
    },

    async [ProjectDocumentsActions.A_DOWNLOAD_DATA]({}, payload: any) {
        const apiMethod = methods[payload.api](payload.data);
        await http.get(apiMethod.url, {params: apiMethod.params, responseType: 'blob'})
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', payload.name);
                document.body.appendChild(link);
                link.click();
                link.remove();
            });
    },

    async [ProjectDocumentsActions.A_DOWNLOAD_EXECUTIVE_DOCS]({dispatch}, payload) {
        try {
            await dispatch(ProjectDocumentsActions.A_DOWNLOAD_DATA, {
                api: 'files/download-pack-exec-doc',
                data: payload.data,
                name: payload.nameArchive,
            });
        } catch (error) {
            this.dispatch(
                'storeUser/A_ERROR_HANDLER',
                {text: 'Не удалось скачать архив комплектов исполнительной документации', error, setBackError: true},
            );
            return Promise.reject(error);
        }
    },

    async [ProjectDocumentsActions.A_SET_DRAGGING_STATE]({commit}, payload) {
        try {
            commit(ProjectDocumentsMutations.M_SET_DRAGGING_STATE, payload);
        } catch (error) {
            return Promise.reject(error);
        }
    },

    async [ProjectDocumentsActions.A_SET_VIEW_MODE]({commit}, payload) {
        try {
            commit(ProjectDocumentsMutations.M_SET_VIEW_MODE, payload);
        } catch (error) {
            return Promise.reject(error);
        }
    },

    async [ProjectDocumentsActions.A_GET_SET_ACTIONS]({commit}, payload) {
        try {
            const apiMethod = methods['document/packED-actions']({...payload});
            const {data} = await http.get(apiMethod.url, {params: apiMethod.params});
            return data;
        } catch (error) {
            this.dispatch(
                'storeUser/A_ERROR_HANDLER',
                {text: 'Не удалось получить действия и комментарии для комплекта', error, setBackError: true},
            );
            return Promise.reject(error);
        }
    },

    async [ProjectDocumentsActions.A_SET_PACK_PD_ACTIONS]({commit}, payload) {
        try {
            const apiMethod = methods['document/packED-action']({...payload});
            await http.post(apiMethod.url, apiMethod.params);
        } catch (error) {
            this.dispatch(
                'storeUser/A_ERROR_HANDLER',
                {text: 'Не удалось выполнить согласование', error, setBackError: true},
            );
            return Promise.reject(error);
        }
    },
}
