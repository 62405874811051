import { Module } from 'vuex';
import { actions } from './Actions';
import { mutations } from './Mutations';
import { getters } from './Getters';
import { IRootState } from '@store/store';
import { IProjectTasksState } from './interfaces/Interfaces';

const state = {
    DEPENDS_ON_PROJECT_STORE: true,
    projectTasksLoaded: false,
    tasks: [],
    groupsFilter: 'all',
    filters: [],
    filtersApply: false,
} as IProjectTasksState;

export const storeProjectTasks = {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
} as Module<IProjectTasksState, IRootState>
